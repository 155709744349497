<!--部门和派出机构-->
<template>
  <h4>&nbsp;机关部门</h4>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20">
        <el-col :span="3" v-for="(department, index) in departments" :key="index">
          <el-card class="box-card" @click="handleClick(department.name)">
            <div class="leader-info">
              <span class="ellipsis" :title="department.name">{{ department.name }}</span>
              <el-icon>
                <DArrowRight/>
              </el-icon>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

  <el-divider style="margin:20px;color: #ffffff"></el-divider>

  <h4>派出机构及外设站所</h4>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20">
        <el-col :span="4" v-for="(outpost, index) in outposts" :key="index">
          <el-card class="box-card" @click="handleClick(outpost.name)">
            <div class="leader-info">
              <span>{{ outpost.name }}</span>
              <el-icon>
                <DArrowRight/>
              </el-icon>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

  <DepartmentStaffInfo
      :modelValue="showDialog"
      :name="selectedName"
      @close="closeDialog"
  />
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {DArrowRight} from '@element-plus/icons-vue';
import axios from 'axios';
import DepartmentStaffInfo from "@/views/Publicize/DepartmentStaffInfo.vue";

const route = useRoute();

const departments = ref([]);
const outposts = ref([]);
const selectedName = ref(null);
const showDialog = ref(false);

const handleClick = (item) => {
  selectedName.value = item;
  showDialog.value = true;
};

const closeDialog = () => {
  showDialog.value = false;
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 在组件挂载时获取部门数据
onMounted(async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/departments`);
    departments.value = response.data.data;
  } catch (error) {
    console.error('获取机关部门数据失败:', error);
  }

  try {
    const response = await axios.get(`${baseUrl}/webapi/otherDepartments`);
    outposts.value = response.data.data;
  } catch (error) {
    console.error('获取其它部门数据失败:', error);
  }
});
</script>

<style lang="scss" scoped>
.leader-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.box-card {
  margin-bottom: 12px;
}

:deep(.el-card__body) {
  padding: 6px;
}

:deep(.el-card__body):hover {
  background-color: #f3f3f3;
}

.el-col {
  padding-top: 6px;
}

.ellipsis {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  display: inline-block; /* 确保宽度生效 */
  width: 100%; /* 确保宽度占满父容器 */
}
</style>
