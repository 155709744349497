<!--新闻详情-->
<template>
  <div class="new-container container-style">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item @click="goBack">
        <div class="breadcrumb-item-content">
          <el-icon class="back">
            <Back/>
          </el-icon>
          <span class="text">{{ parentName }}</span>
        </div>
      </el-breadcrumb-item>
      <el-breadcrumb-item>新闻</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="article-content">
      <h3>{{ currentNews.title }}</h3>
      <div class="meta-info">
        <span class="author">
          作者：{{ currentNews.author }}
          <span v-if="currentNews.source">
            （信息来源：{{ currentNews.source }}）
          </span>
        </span>
        <span class="time">发布时间：{{ whichTime(currentNews.publicationTime) }}</span>
        <div class="font">
          <span>【字体：</span>
          <span @click="setFontSize('small')">小</span>
          <span @click="setFontSize('default')">中</span>
          <span @click="setFontSize('large')">大</span>
          <span>】</span>
          <span class="clicks">阅读 {{ currentNews.clicks }}</span>
        </div>
      </div>
      <hr/>
      <div v-html="currentNews.content" :class="fontSizeClass"></div>
    </div>
  </div>
</template>


<script setup>
import {ref, watchEffect, onBeforeUnmount, computed} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import moment from "moment";
import {Back} from "@element-plus/icons-vue";

moment.locale("zh-cn");

const route = useRoute();
const router = useRouter();
const currentNews = ref({});
const fontSize = ref('default'); // 默认字体大小

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 计算父组件的名称
const parentName = computed(() => {
  const parentTitle = route.query.parentTitle;
  return parentTitle || '未知';
});

const stop = watchEffect(async () => {
  if (!route.params.id) return;
  const res = await axios.get(`${baseUrl}/webapi/news/list/${route.params.id}`);
  currentNews.value = res.data.data;
});


onBeforeUnmount(() => {
  stop();
});

const whichTime = time => {
  return moment(time).format("lll");
};

const goBack = () => {
  router.back();
};

const setFontSize = (size) => {
  fontSize.value = size;
};

const fontSizeClass = computed(() => {
  return {
    'font-default': fontSize.value === 'default',
    'font-small': fontSize.value === 'small',
    'font-large': fontSize.value === 'large'
  };
});
</script>

<style lang="scss" scoped>
.new-container {
  width: 1200px;
  background-color: #fdfdfd;
  margin: 20px auto;
  padding: 40px 60px;

  .el-breadcrumb {
    font-size: 15px;
  }

  .breadcrumb-item-content {
    display: flex;
    align-items: center;

    .back {
      margin-right: 6px;
      cursor: pointer;
    }

    .text {
      margin: 0;
    }
  }

  .article-content {
    h3 {
      text-align: center;
      margin-bottom: 16px;
    }

    .meta-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;
      margin-bottom: 10px;
      font-size: 14px;

      .author {
        flex: 1; // 使 author 占据剩余空间并居左
        text-align: left;
      }

      .time {
        flex: 1; // 使 time 占据剩余空间并居中
        text-align: center;
      }

      .font {
        flex: 1; // 使 font 占据剩余空间并居右
        text-align: right;

        span {
          margin: 0 5px;
        }

        .clicks {
          margin-left: 30px;
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }

    .font {
      margin-bottom: 10px;

      span {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .content {
      p {
        font-size: 15px;
        line-height: 3; // 设置全局行距
        margin-bottom: 20px;
      }

      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;

        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        th {
          background-color: #f2f2f2;
        }
      }
    }

    .font-default {
      font-size: 16px;
      line-height: 1.8;
    }

    .font-small {
      font-size: 14px;
      line-height: 1.6;
    }

    .font-large {
      font-size: 18px;
      line-height: 1.9;
    }


  }
}
</style>
