import { createStore } from "vuex";

export default createStore({
  state: {
    searchResults: {
      departments: [],
      news: [],
      persons: []
    }
  },
  getters: {},
  mutations: {
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results;
    }
  },
  actions: {
    setSearchResults({ commit }, results) {
      commit('SET_SEARCH_RESULTS', results);
    }
  },
  modules: {},
});
