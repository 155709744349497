<!--政务公开-->
<template>
  <div
    :style="{
      width: '100%',
      height: '160px',
      'background-size': 'cover',
      'background-image': `url(/publicize/政务公开背景.jpg)`
    }"
  ></div>

  <el-header>
    <el-menu mode="horizontal" :default-active="activeMenuItem">
      <el-menu-item @click="handleMenuItemClick(1)">圳上镇党委</el-menu-item>
      <el-menu-item @click="handleMenuItemClick(2)">圳上镇政府</el-menu-item>
      <el-menu-item @click="handleMenuItemClick(3)">圳上镇人大</el-menu-item>
      <el-menu-item @click="handleMenuItemClick(4)">圳上镇纪委</el-menu-item>
      <el-menu-item @click="handleMenuItemClick(5)">圳上镇人武部</el-menu-item>
    </el-menu>
  </el-header>

  <el-main>
    <el-row :gutter="20" class="box-card">
      <el-col :span="12" v-if="showLeader">
        <ShowLeader />
      </el-col>

      <el-col :span="12" v-if="showLeaderStaffs">
        <ShowLeaderStaffs :category="selectedCategory" />
      </el-col>

      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="24" style="height: 360px">
            <el-divider style="margin: 0"></el-divider>
            <el-divider
                style="margin: 0; width: 65px; height: 3px; background-color: #439bf1; border-color: #439bf1;"></el-divider>
            <h4>工作动态</h4>
            <ul v-if="newsList.length" class="news-list">
              <li v-for="news in newsList" :key="news._id" class="news-item">
                <a href="#" class="news-title" @click.prevent="handleNewsClick(news._id, '工作动态')">
                  {{ news.title }}
                </a>
                <span class="news-date">{{ news.publicationTime.slice(0, 10) }}</span>
              </li>
            </ul>
          </el-col>

          <el-col :span="24">
            <el-divider style="margin: 10px 0 0 0"></el-divider>
            <el-divider
                style="margin: 0; width: 97px; height: 3px; background-color: #439bf1; border-color: #439bf1;"
            ></el-divider>
            <h4 style="margin-bottom: 13px">政府工作报表</h4>
            <el-row :gutter="10">
              <el-col :span="6" v-for="(report, index) in governmentReport" :key="index">
                <el-card class="report-card" @click="handleReportClick(report._id)">
                  <img src="/publicize/政府工作报告.png" alt="Folder Icon">
                  <div class="year-circle">{{ report.year }}</div>
                  <p>{{ report.title1 }}</p>
                  <p>{{ report.title2 }}</p>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-main>

  <DepartmentsAndOutposts />
</template>


<script setup>
import {ref, onMounted} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import DepartmentsAndOutposts from '@/views/Publicize/DepartmentsAndOutposts.vue';
import ShowLeader from '@/views/Publicize/ShowLeader.vue';
import ShowLeaderStaffs from '@/views/Publicize/ShowLeaderStaffs.vue';

const route = useRoute();
const router = useRouter();

const newsList = ref([]);
const governmentReport = ref([]);
const activeMenuItem = ref('1');
const showLeader = ref(true);
const showLeaderStaffs = ref(false);
const selectedCategory = ref(null);

const baseUrl = process.env.VUE_APP_API_BASE_URL;

const handleReportClick = (id) => {
  router.push({
    path: `/new/${id}`,
    query: { parentTitle: '政府工作报告' }
  });
};

const handleMenuItemClick = (index) => {
  activeMenuItem.value = index.toString();
  showLeader.value = false;
  showLeaderStaffs.value = true;
  selectedCategory.value = index;
};

const handleNewsClick = (id, tabName) => {
  router.push({
    path: `/new/${id}`,
    query: { parentTitle: tabName }
  });
};

// 获取工作动态列表
const fetchNewsData = async () => {
  const category = 5; // 5:工作动态
  const newsCount = 10;
  try {
    const response = await axios.get(`${baseUrl}/webapi/news/category/${category}?limit=${newsCount}`);
    newsList.value = response.data.data || [];
  } catch (error) {
    console.error("获取工作动态列表失败:", error);
  }
};

// 获取政府工作报告
const fetchGovernmentReport = async () => {
  const top = 4;
  try {
    const response = await axios.get(`${baseUrl}/webapi/publicize/report/${top}`);
    governmentReport.value = response.data.data.map(report => ({
      ...report,
      year: report.title.substring(0, 4),
      title1: report.title.substring(0, 8),
      title2: report.title.substring(8)
    }));
  } catch (error) {
    console.error('获取政府工作报告失败:', error);
    governmentReport.value = [];
  }
};

onMounted(() => {
  fetchNewsData();
  fetchGovernmentReport();
});
</script>

<style lang="scss" scoped>
.el-header {
  padding: 0;
  .el-menu {
    display: inline-block;
    width: 100%;
    min-width: 1200px;
    height: 49px;
    margin: 0 auto;
    padding: 8px 0 2px;
    text-align: center;
    background: linear-gradient(to right, #66adf2, #f9f9f9, #66adf2);

    .el-menu-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      transition: box-shadow 0.3s ease;
      &:hover {
        color: #333;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.box-card {
  margin-bottom: 20px;
}

.el-main {
  padding: 20px 20px 20px 0;
}
:deep(.el-card__body) {
  padding: 2px 1px 5px 2px;
}

.news-list {
  list-style: none;
  margin-top: 10px;

  .news-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0 12px;
    font-size: 17px;
    &::before {
      content: "•";
      margin-right: 8px;
      color: #888;
    }

    .news-title {
      color: #444444;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
      font-size: 16px;
      &.active {
        color: #c72c1a;
      }
      &:hover {
        color: #439bf1;
      }
    }
    .news-date {
      color: #444444;
      font-size: 14px;
      margin-left: auto;
    }
  }
}

.report-card {
  padding: 20px 6px 6px 6px;
  display: grid;
  place-items: center;
  position: relative;
  text-align: center;
  background-color: #fbfbfb;
  cursor: pointer;

  .year-circle {
    position: absolute;
    top: 74px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 17px;
  }
}
</style>

