// 手风琴菜单组件，在纯朴圳上.圳上人文中调用
<template>
  <div class="accordion-item">
    <div class="accordion-header" @click="toggle">
      {{ title }}
      <el-icon :class="{ 'rotate-180': !isOpen }"><ArrowUp /></el-icon>
    </div>
    <div class="accordion-content" v-if="isOpen">
      <div class="content-wrapper">
        <div class="text-content">
          <p class="content-part">{{ contentPart1 }}</p>
          <p class="content-part">{{ contentPart2 }}</p>
        </div>
        <div class="image-content">
          <img :src="imageUrl" alt="图片说明" width="220" height="290" />
          <p class="image-caption">{{ imageCaption }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ArrowUp } from '@element-plus/icons-vue';

const props = defineProps({
  title: String,
  contentPart1: String,
  contentPart2: String,
  imageUrl: String,
  imageCaption: String
});

const isOpen = ref(true); // 默认展开

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style lang="scss" scoped>
.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.accordion-header .el-icon {
  transition: transform 0.3s;
}

.accordion-header .rotate-180 {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 10px;
  background-color: #fff;
  border-top: none;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

.text-content {
  flex: 1;
  margin-right: 20px;
}

.image-content {
  text-align: center;
}

.image-content img {
  width: 220px;
  height: 290px;
  object-fit: cover;
}

.image-caption {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.content-part {
  line-height: 1.8; /* 设置内容部分的行间距为1.8 */
}
</style>



