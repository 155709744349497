<!--新闻热点-->
<template>
  <div class="news-container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item @click="router.go(-1)">
        <div class="breadcrumb-item-content">
          <el-icon class="back">
            <Back />
          </el-icon>
          <span class="text">热点专题</span>
        </div>
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="20">
      <el-col :span="18">
        <div
            v-for="data in newlist"
            :key="data._id"
            class="tab-item"
            style="padding: 10px 10px 10px 0;"
            @click="handleChangePage(data._id)"
        >
          <el-card
              :body-style="{ padding: 0 }"
              shadow="hover"
              class="pointer"
          >
            <div
                :style="{ backgroundImage: `url(${baseUrl}${data.cover})` }"
                class="tab-image"
            ></div>
            <div style="padding: 14px; float: left;">
              <span class="news-title">{{ data.title }}</span>
              <div class="bottom">
                <time class="tab-time">{{ whichTime(data.publicationTime) }}</time>
              </div>
              <span class="news-author">作者：{{ data.author }}</span>
              <span class="clicks">阅读 {{ data.clicks }}</span>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="6">
        <el-timeline>
          <el-timeline-item
              v-for="(data, index) in newlist"
              :key="index"
              :timestamp="whichTime(data.editTime)"
          >
            <span class="pointer" @click="handleChangePage(data._id)">{{ data.title }}</span>
          </el-timeline-item>
        </el-timeline>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';
import axios from 'axios';
import { ElBreadcrumb, ElBreadcrumbItem, ElIcon } from 'element-plus';
import { Back } from '@element-plus/icons-vue';

moment.locale('zh-cn');

// 获取路由参数
const route = useRoute();

// 从路由 query 中获取 hot 和 title
const hot = route.query.link
const title = route.query.title

const newlist = ref([]);

const whichTime = time => moment(time).format('lll');

const handleChangePage = (id) => {
  // 通过查询参数传递当前标题
  router.push({
    path: `/new/${id}`,
    query: { parentTitle: title }
  });
};

const router = useRouter(); // 初始化 router

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

onMounted(async () => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/newsHot/hot`, {
      params: { hot:hot } // 使用分类参数
    });
    newlist.value = res.data.data; // 这里仍然有问题，应改为 res.data.data
  } catch (error) {
    console.error('获取热点专题失败:', error);
  }
});
</script>

<style lang="scss" scoped>
.news-container {
  width: 100%;
  margin-top: 20px;

  .breadcrumb-item-content {
    display: flex;
    align-items: center;

    .back {
      margin-right: 10px; // 可选：添加一些间距
    }

    .text {
      font-size: 14px;
      color: #414141
    ;
    }
  }

  .news-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .tab-time {
      font-size: 13px;
      color: gray;
    }

    .clicks {
      font-size: 13px;
      color: gray;
    }
  }
}

.tab-image {
  width: 150px;
  height: 100px;
  background-size: cover;
  float: left;
}

.news-author, .clicks {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.pointer {
  cursor: pointer;
}
</style>
