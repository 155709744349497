<template>
  <div class="homeLink container-style">
    <el-tabs :tab-position="'left'">
      <el-tab-pane label="个人服务">
        <el-row :gutter="10">
          <el-col :span="4" v-for="(link, index) in personalLinks" :key="index" @click="openLink(link)">
            <div class="link-item">
              {{ link.label }}
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="法人服务">
        <el-row :gutter="10">
          <el-col :span="4" v-for="(link, index) in legalPersonLinks" :key="index" @click="openLink(link)">
            <div class="link-item">
              {{ link.label }}
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="部门服务">
        <el-row :gutter="10">
          <el-col :span="4" v-for="(link, index) in departmentLinks" :key="index" @click="openLink(link)">
            <div class="link-item">
              {{ link.label }}
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'

// 定义网址链接数组，个人服务
const links1 = ref([
  {
    label: '生育收养',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=005&typename=%25u6237%25u7C4D%25u529E%25u7406&areacode=431322999000&main=1'
  },
  {
    label: '户籍办理',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=010&typename=%25u6237%25u7C4D%25u529E%25u7406&areacode=431322999000&main=1'
  },
  {
    label: '就业创业',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=030&typename=%25u5C31%25u4E1A%25u521B%25u4E1A&areacode=431322999000&main=1'
  },
  {
    label: '优待抚恤',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=065&typename=%25u4F18%25u5F85%25u629A%25u6064&areacode=431322999000&main=1'
  },
  {
    label: '离职退休',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=150&typename=%25u79BB%25u804C%25u9000%25u4F11&areacode=431322999000&main=1'
  },
  {
    label: '证件办理',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=090&typename=%25u8BC1%25u4EF6%25u529E%25u7406&areacode=431322999000&main=1'
  },
  {
    label: '婚姻登记',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=060&typename=%25u5A5A%25u59FB%25u767B%25u8BB0&areacode=431322999000&main=1'
  },
  {
    label: '民族宗教',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=015&typename=%25u6C11%25u65CF%25u5B97%25u6559&areacode=431322999000&main=1'
  },{
    label: '职业资格',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=050&typename=%25u804C%25u4E1A%25u8D44%25u683C&areacode=431322999000&main=1'
  },{
    label: '公共事业',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=140&typename=%25u516C%25u7528%25u4E8B%25u4E1A&areacode=431322999000&main=1'
  },{
    label: '住房保障',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=075&typename=%25u4F4F%25u623F%25u4FDD%25u969C&areacode=431322999000&main=1'
  },
  {
    label: '教育科研',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=020&typename=%25u6559%25u80B2%25u79D1%25u7814&areacode=431322999000&main=1'
  },
  {
    label: '医疗卫生',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=040&typename=%25u4E0D%25u4EBA%25u4EBA%25u4F53%25u8BA4&areacode=431322999000&main=1'
  },
  {
    label: '死亡殡葬',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=155&typename=%25u6B7B%25u4EA1%25u6BA1%25u846C&areacode=431322999000&main=1'
  },
  {
    label: '交通出行',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=095&typename=%25u4EA4%25u901A%25u51FA%25u884C&areacode=431322999000&main=1'
  },
  {
    label: '公共安全',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=115&typename=%25u516C%25u5171%25u5B89%25u5168&areacode=431322999000&main=1'
  },
  {
    label: '司法公证',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=120&typename=%25u53F8%25u6CD5%25u516C%25u8BC1&areacode=431322999000&main=1'
  },
  {
    label: '更多...',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=999&typename=%25u5176%25u4ED6&areacode=431322999000&main=1'
  },
])


// 定义网址链接数组，法人服务
const links2 = ref([
  {
    label: '设立变更',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=005&typename=%25u8BBE%25u7ACB%25u53D8%25u66F4&areacode=431322999000&main=1'
  },
  {
    label: '准营准办',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=010&typename=%25u51C6%25u8425%25u51C6%25u529E&areacode=431322999000&main=1'
  },
  {
    label: '资质认证',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=015&typename=%25u8D44%25u8D28%25u8BA4%25u8BC1&areacode=431322999000&main=1'
  },
  {
    label: '年检年审',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=020&typename=%25u5E74%25u68C0%25u5E74%25u5BA1&areacode=431322999000&main=1'
  },
  {
    label: '税收财务',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=025&typename=%25u7A0E%25u6536%25u8D22%25u52A1&areacode=431322999000&main=1'
  },
  {
    label: '人力资源',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=030&typename=%25u4EBA%25u529B%25u8D44%25u6E90&areacode=431322999000&main=1'
  },
  {
    label: '社会保障',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=035&typename=%25u793E%25u4F1A%25u4FDD%25u969C&areacode=431322999000&main=1'
  },
  {
    label: '投资审批',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=040&typename=%25u6295%25u8D44%25u5BA1%25u6279&areacode=431322999000&main=1'
  },
  {
    label: '农林牧渔',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=075&typename=%25u519C%25u6797%25u7267%25u6E14&areacode=431322999000&main=1'
  },
  {
    label: '国土规划',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=085&typename=%25u56FD%25u571F%25u548C%25u89C4%25u5212%25u5EFA%25u8BBE&areacode=431322999000&main=1'
  },
  {
    label: '交通运输',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=090&typename=%25u4EA4%25u901A%25u8FD0%25u8F93&areacode=431322999000&main=1'
  },
  {
    label: '环保绿化',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtgr&typeid=130&typename=%25u73AF%25u4FDD%25u7EFF%25u5316&areacode=431322999000&main=1'
  },
  {
    label: '医疗卫生',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=110&typename=%25u533B%25u7597%25u536B%25u751F&areacode=431322999000&main=1'
  },
  {
    label: '检验检疫',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=140&typename=%25u68C0%25u9A8C%25u68C0%25u75AB&areacode=431322999000&main=1'
  },
  {
    label: '安全生产',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=145&typename=%25u5B89%25u5168%25u751F%25u4EA7&areacode=431322999000&main=1'
  },
  {
    label: '公安消防',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=150&typename=%25u516C%25u5B89%25u6D88%25u9632&areacode=431322999000&main=1'
  },
  {
    label: '公用事业',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=160&typename=%25u516C%25u7528%25u4E8B%25u4E1A&areacode=431322999000&main=1'
  },
  {
    label: '更多...',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtfr&typeid=999&typename=%25u5176%25u4ED6&areacode=431322999000&main=1'
  },
])

// 定义网址链接数组，法人服务
const links3 = ref([
  {
    label: '县发改委',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=eb0fb6baea0f4dd58db0ad3ad204b113&main=1&orgname=%25u53BF%25u53D1%25u6539%25u5C40&typeid=eb0fb6baea0f4dd58db0ad3ad204b113&typename=%25u53BF%25u53D1%25u6539%25u5C40&areacode=431322999000'
  },
  {
    label: '县教育局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=acb394d351e34566b9c7a90b8eb741c7&main=1&orgname=%25u53BF%25u6559%25u80B2%25u5C40&typeid=acb394d351e34566b9c7a90b8eb741c7&typename=%25u53BF%25u6559%25u80B2%25u5C40&areacode=431322999000'
  },
  {
    label: '县公安局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=aecc691c8694430da4ebce02e584eb5c&main=1&orgname=%25u53BF%25u516C%25u5B89%25u5C40&typeid=aecc691c8694430da4ebce02e584eb5c&typename=%25u53BF%25u516C%25u5B89%25u5C40&areacode=431322999000'
  },
  {
    label: '县民政局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=e1e332bc776841c2aad2261a923ab8cd&main=1&orgname=%25u53BF%25u6C11%25u653F%25u5C40&typeid=e1e332bc776841c2aad2261a923ab8cd&typename=%25u53BF%25u6C11%25u653F%25u5C40&areacode=431322999000'
  },
  {
    label: '县财政局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=655e1050a1794c07aedd00eb0fb30df6&main=1&orgname=%25u53BF%25u8D22%25u653F%25u5C40&typeid=655e1050a1794c07aedd00eb0fb30df6&typename=%25u53BF%25u8D22%25u653F%25u5C40&areacode=431322999000'
  },
  {
    label: '县农业农村局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=a920e699709d4f40b8185dc008224862&main=1&orgname=%25u53BF%25u519C%25u4E1A%25u519C%25u6751%25u5C40&typeid=a920e699709d4f40b8185dc008224862&typename=%25u53BF%25u519C%25u4E1A%25u519C%25u6751%25u5C40&areacode=431322999000'
  },

  {
    label: '县市监局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=722ccafb660748e4942c81d4783dbf89&main=1&orgname=%25u53BF%25u5E02%25u76D1%25u5C40&typeid=722ccafb660748e4942c81d4783dbf89&typename=%25u53BF%25u5E02%25u76D1%25u5C40&areacode=431322999000'
  },
  {
    label: '县消防大队',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=371b660a7c71466f868bceda1ba2df80&main=1&orgname=%25u53BF%25u6D88%25u9632%25u5927%25u961F&typeid=371b660a7c71466f868bceda1ba2df80&typename=%25u53BF%25u6D88%25u9632%25u5927%25u961F&areacode=431322999000'
  },

  {
    label: '县就业服务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=24d30c70204240adbbb8f0f96ab67158&main=1&orgname=%25u53BF%25u5C31%25u4E1A%25u670D%25u52A1%25u4E2D%25u5FC3&typeid=24d30c70204240adbbb8f0f96ab67158&typename=%25u53BF%25u5C31%25u4E1A%25u670D%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县工伤保险服务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=6b528401d12c4f88a25d9be73f8837f0&main=1&orgname=%25u53BF%25u5DE5%25u4F24%25u4FDD%25u9669%25u670D%25u52A1%25u4E2D%25u5FC3&typeid=6b528401d12c4f88a25d9be73f8837f0&typename=%25u53BF%25u5DE5%25u4F24%25u4FDD%25u9669%25u670D%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县医疗保险服务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=a88fb75fe4ba4e4db3f5ea958fd547c7&main=1&orgname=%25u53BF%25u533B%25u7597%25u4FDD%25u969C%25u4E8B%25u52A1%25u4E2D%25u5FC3&typeid=a88fb75fe4ba4e4db3f5ea958fd547c7&typename=%25u53BF%25u533B%25u7597%25u4FDD%25u969C%25u4E8B%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县社会保险服务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=f2c14c2974864609913f3c7333be833e&main=1&orgname=%25u53BF%25u793E%25u4F1A%25u4FDD%25u9669%25u670D%25u52A1%25u4E2D%25u5FC3&typeid=f2c14c2974864609913f3c7333be833e&typename=%25u53BF%25u793E%25u4F1A%25u4FDD%25u9669%25u670D%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县住房保障服务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=7c57ebed6940414abc0fbf7ed1e5b4c3&main=1&orgname=%25u53BF%25u4F4F%25u623F%25u4FDD%25u969C%25u670D%25u52A1%25u4E2D%25u5FC3&typeid=7c57ebed6940414abc0fbf7ed1e5b4c3&typename=%25u53BF%25u4F4F%25u623F%25u4FDD%25u969C%25u670D%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县农机事务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=f4fea1bf522840d3aa42befa93afa761&main=1&orgname=%25u53BF%25u519C%25u673A%25u4E8B%25u52A1%25u4E2D%25u5FC3&typeid=f4fea1bf522840d3aa42befa93afa761&typename=%25u53BF%25u519C%25u673A%25u4E8B%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县农村经营事务中心',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=a4cb1734662e4fe89e40512a7399fd6f&main=1&orgname=%25u53BF%25u519C%25u6751%25u7ECF%25u8425%25u4E8B%25u52A1%25u4E2D%25u5FC3&typeid=a4cb1734662e4fe89e40512a7399fd6f&typename=%25u53BF%25u519C%25u6751%25u7ECF%25u8425%25u4E8B%25u52A1%25u4E2D%25u5FC3&areacode=431322999000'
  },
  {
    label: '县住房公积金管理部',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=5bed2bd276c6450281f82ee268f133b8&main=1&orgname=%25u53BF%25u4F4F%25u623F%25u516C%25u79EF%25u91D1%25u7BA1%25u7406%25u90E8&typeid=5bed2bd276c6450281f82ee268f133b8&typename=%25u53BF%25u4F4F%25u623F%25u516C%25u79EF%25u91D1%25u7BA1%25u7406%25u90E8&areacode=431322999000'
  },
  {
    label: '县退役军人事务局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=ZUlibOojHT2mlb22MmRg8Kg&main=1&orgname=%25u53BF%25u9000%25u5F79%25u519B%25u4EBA%25u4E8B%25u52A1%25u5C40&typeid=ZUlibOojHT2mlb22MmRg8Kg&typename=%25u53BF%25u9000%25u5F79%25u519B%25u4EBA%25u4E8B%25u52A1%25u5C40&areacode=431322999000'
  },
  {
    label: '县气象局',
    params: 'https://zwfw-new.hunan.gov.cn/onething/service/index.jsp?type=xndtbm&orgId=3874eb033b834fa5ade4ddb7846ceaaf&main=1&orgname=%25u53BF%25u6C14%25u8C61%25u5C40&typeid=3874eb033b834fa5ade4ddb7846ceaaf&typename=%25u53BF%25u6C14%25u8C61%25u5C40&areacode=431322999000'
  },
])

// 定义三个标签页的链接数组
const personalLinks = ref([])
const legalPersonLinks = ref([])
const departmentLinks = ref([])

// 分配链接到三个标签页
onMounted(() => {
  personalLinks.value = links1.value
  legalPersonLinks.value = links2.value
  departmentLinks.value = links3.value
})

// 打开链接的方法
const openLink = (link) => {
  window.open(link.params, '_blank');
}
</script>

<style scoped lang="scss">
.homeLink {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 调整对齐方式 */
  background-color: #d5e3f1;
  padding: 20px 10px 20px 0;

  :deep(.el-tabs__item) {
    font-size: 16px;
    font-weight: bold;
  }

  .link-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid #ebeef5;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    transition: background-color 0.3s;
    margin: 10px;
    cursor: pointer;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏溢出内容 */
    text-overflow: ellipsis; /* 显示省略号 */
    font-size: 16px;
    font-weight: bold;

    /* 鼠标悬停效果 */
    &:hover {
      color: #449bf1;
      background-color: #fbfbfb;
      border: 2px solid #dbdbdb;
    }
  }
}
</style>