<!-- 页顶部模块，用来显示当前日期、农历、天气信息、登录等。-->
<template>
  <!-- 顶 -->
  <div class="top_bg">
    <nav class="top">
      <ul>
        <li>{{ currentDate }}</li>
        <li>
          <Weather/>
        </li>
        <li class="login" @click="handleClick">登录</li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import Weather from '@/components/Weather.vue';

const currentDate = ref('');

// 更新时间
const updateDate = () => {
  const date = new Date();
  const weekday = date.toLocaleDateString('zh-CN', {weekday: 'long'}).trim();
  const dateString = date.toLocaleDateString('zh-CN', {year: 'numeric', month: 'long', day: 'numeric'});
  currentDate.value = `${dateString} | ${weekday}`;
};

// 登录
const handleClick = () => {
  // window.location.href = "http://localhost:8081";
};

onMounted(() => {
  updateDate();
});
</script>

<style lang="scss" scoped>
.top_bg {
  width: 100%;
  background-color: #439bf1;

  .top {
    width: 1200px;
    margin: 0 auto;

    .login {
      margin-left: auto;
    }

    ul {
      display: flex;
      list-style-type: none;

      li {
        color: white;
        line-height: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
