// 人员详情
<template>
    <el-dialog
        v-model="dialogVisible"
        width="890px"
        destroy-on-close
        class="container-style"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="staff-details">
            <img :src="selectedStaff.avatar" :alt="selectedStaff.name" class="staff-avatar"/>
            <h4>{{ selectedStaff.position }} {{ selectedStaff.name }}</h4>
          </div>
        </el-col>

        <el-col :span="18">
          <div style="min-height: 30px">
            <h3>简历:</h3>
            <p>　　{{ selectedStaff.biography }}</p>
          </div>

          <el-divider style="margin: 12px 0 20px 0"></el-divider>

          <div style="min-height: 30px">
            <h3>工作分工:</h3>
            <p>　　{{ selectedStaff.responsibility }}</p>
          </div>

          <el-divider style="margin: 12px 0 20px 0"></el-divider>

          <el-row>
            <el-col :span="15">
              <p>联系方式：{{ formattedPhone }}</p>
            </el-col>

            <el-col :span="9">
              <p>入职时间：{{ selectedStaff.hireDate }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
</template>

<script setup>
import {ref, watch, computed} from 'vue';
import axios from "axios";

const props = defineProps({
  modelValue: Boolean,
  id: String
});

const emit = defineEmits(['update:modelValue']);

// 强制 dialogVisible 为 true
const dialogVisible = ref(true);
const selectedStaff = ref({
  avatar: '',
  name: '',
  position: '',
  biography: '',
  responsibility: '',
  hireDate: '',
  phone: ''
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 获取人员信息
const fetchStaffInfo = async (id) => {
  if (id) {
    try {
      const response = await axios.get(`${baseUrl}/webapi/publicize/staff/${id}`);
      const staffData = response.data;

      // 格式化入职时间
      if (staffData.hireDate) {
        const hireDate = new Date(staffData.hireDate);
        staffData.hireDate = hireDate.toISOString().slice(0, 10);
      }

      selectedStaff.value = staffData;
    } catch (error) {
      console.error('获取人员信息失败:', error);
      selectedStaff.value = {
        avatar: '',
        name: '',
        position: '',
        biography: '',
        responsibility: '',
        hireDate: '',
        phone: ''
      };
    }
  }
};

watch(
    () => props.id,
    async (id) => {
      await fetchStaffInfo(id);
    },
    {immediate: true}
);

// 计算属性，用于格式化电话号码
const formattedPhone = computed(() => {
  const phone = selectedStaff.value.phone;
  if (phone && phone.length === 11) {
    return phone.slice(0, 3) + '****' + phone.slice(7);
  }
  return phone;
});
</script>

<style lang="scss" scoped>
.staff-details {
  .staff-avatar {
    width: 206px;
    height: 289px;
    object-fit: cover;
  }

  h4 {
    margin: 8px 0 10px 0;
    text-align: center; /* 添加这一行 */
    font-size: 15px;
  }
}

h3 {
  margin-bottom: 10px;
  color: #006eaa;
}

p {
  font-size: 15px;
  line-height: 1.8;
}
</style>
