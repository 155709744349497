// 轮播图组件
<template>
  <div :style="{ width: width, height: height }">
    <el-carousel
        height="auto"
        indicator-position="outside"
        v-if="images.length > 0"
    >
      <el-carousel-item
          v-for="(image, index) in images"
          :key="index"
          :style="{width:width, height: height }"
      >
        <img
            :src="image.src"
            alt="Carousel Image"
        >
        <div class="carousel-caption" v-if="image.title">
          {{ image.title }}
        </div>
      </el-carousel-item>
    </el-carousel>
    <div v-else>
      加载图片出错。
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {ElCarousel, ElCarouselItem} from "element-plus";

const props = defineProps({
  width: {
    type: String,
    required: true
  },
  height: {
    type: String,
    required: true
  },
  images: {
    type: Array,
    required: true,
    default: () => []
  }
});

// 图片数据
const items = ref(props.images);
</script>

<style lang="scss" scoped>
.carousel-image {
  display: block; // 确保图片没有多余的空白
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  padding-left: 8px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: white;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0));
}

/* 调整指示器样式 */
::v-deep(.el-carousel__indicators--outside) {
  position: absolute;
  left: auto;
  right: 12px;
  bottom: -5px;
  padding-right: 12px;
}

::v-deep(.el-carousel__indicators--outside .el-carousel__button) {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e04848;
  transition: background-color 0.3s ease;
}

::v-deep(.el-carousel__indicators--outside .is-active .el-carousel__button) {
  background-color: #c71f1f;
}

.loading-icon {
  width: 80px;
  height: 80px;
}
</style>
