<!--圳上概述-->
<template>
  <div class="overview-container">
    <p class="preserve-newlines" v-html="formattedOverview"></p>
  </div>
</template>

<script setup>
const data = {
  overview: "圳上，因处圳水之上而得名。\n" +
      "圳上镇，隶属湖南省娄底市新化县，介于东经111°14'31\"-111°26'28\"，北纬28°00'09\"-28°11'38\"之间，属中亚热带季风湿润气候。地处新化县东北部，东接安化县乐安镇，南邻吉庆镇，西抵白溪镇，北连安化县江南镇，毗邻大熊山国家森林公园，是进入大熊山这座自然宝库的主要门户。\n" +
      "全镇行政区域面积264平方千米，户籍人口6.73万多人，有耕地面积41190亩，其中水田31080亩，旱土10110亩，山林24.9万多亩。\n" +
      "山的雄伟、水的灵性和乡里人的纯朴，使这里孕育了一代又一代名震中外的风云人物。原黄埔军校教育长、代校长方鼎英，华夏名将陈正湘，国际共产主义战士罗盛教，生于斯，长于斯。\n" +
      "这里，种灵毓秀，人杰地灵。\n" +
      "这里，民风淳朴，村民善良、朴实、勤劳、尚德崇勇。\n" +
      "这里，村庄藏于青山绿水之间，农舍隐于阡陌之后，鸡犬相闻，好一幅小桥、流水、人家的美丽山水画。\n" +
      "这里，不仅自然风光旖旎，山川秀美，更是人文荟萃之地。\n" +
      "这里，还是一片养于深闺的处女地。"
};

// 处理字符串，在每一行前增加两个中文空格
const formattedOverview = data.overview.split('\n').map(line => '　　' + line).join('<br>');
</script>

<style scoped>
.overview-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
}

.preserve-newlines {
  white-space: pre-wrap; /* 保留换行符并允许文本换行 */
  font-size: 16px;
  line-height: 2;
  color: #333;
}
</style>
