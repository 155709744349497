<!--纯朴圳上主模块-->
<template>
  <!-- 轮播图 -->
  <Carousel :width="carouselWidth" :height="carouselHeight" :images="carouselItems"/>
  <!-- 导航栏 --->
  <el-menu mode="horizontal" router>
    <el-menu-item index="/gozsz/overview">圳上概述</el-menu-item>
    <el-menu-item index="/gozsz/history">历史沿革</el-menu-item>
    <el-menu-item index="/gozsz/administration">行政区划</el-menu-item>
    <el-menu-item index="/gozsz/culture">圳上人文</el-menu-item>
    <el-menu-item index="/gozsz/geography">自然地理</el-menu-item>
  </el-menu>

  <!-- 正文 --->
  <div class="content-container">
    <router-view></router-view>
  </div>
</template>


<script setup>
import Carousel from '@/components/Carousel.vue';
import {onMounted, ref} from 'vue';
import axios from "axios";

const carouselWidth = "100%";
const carouselHeight = "360px";
// 轮播图片数据
const carouselItems = ref([]);
// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;
// 获取轮播图数据
const fetchCarouselData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/carousel/list/gozsz`);
    const data = response.data.data;

    // 合并图片地址和图片标题
    carouselItems.value = data.map(item => ({
      src: item.cover,
      title: item.title
    }));
  } catch (error) {
    console.error("Error fetching carousel data:", error);
    alert("无法获取轮播图数据，请稍后再试。");
  }
};

// 在组件挂载时获取数据
onMounted(() => {
  fetchCarouselData();
});
</script>


<style lang="scss" scoped>
.el-menu {
  display: inline-block;
  width: 100%;
  min-width: 1200px;
  height: 49px;
  margin: 0 auto;
  padding: 8px 0 2px;
  text-align: center;
  background: linear-gradient(to right, #94c3f3, #f9f9f9, #94c3f3); /* 背景渐变 */

  .el-menu-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: black;

    transition: box-shadow 0.3s ease;

    &:hover {
      color: #333;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.content-container {
  width: 1200px;
  margin: 20px auto;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* 确保轮播图图片填充满整个容器 */
.carousel-container {
  width: 100%;
  height: 362px; /* 设置轮播图高度 */
  overflow: hidden;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保图片填充满整个容器 */
  display: block;
}
</style>

