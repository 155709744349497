<!-- 领导之窗 -->
<template>
  <div class="container">
    <el-row :gutter="20">
      <!-- 书记 -->
      <el-col :span="6" v-if="leaders.secretaries && leaders.secretaries.length > 0">
        <el-card class="box-card">
          <div class="leader-info" @click="handleClick(leaders.secretaries[0]._id)">
            <img :src="leaders.secretaries[0].avatar" :alt="leaders.secretaries[0].name" class="leader-image"/>
            <span class="leader-name">{{ leaders.secretaries[0].name }}</span>
            <span class="leader-position">{{ leaders.secretaries[0].position }}</span>
          </div>
        </el-card>
      </el-col>

      <!-- 镇长 -->
      <el-col :span="6" v-if="leaders.mayors && leaders.mayors.length > 0">
        <el-card class="box-card">
          <div class="leader-info" @click="handleClick(leaders.mayors[0]._id)">
            <img :src="leaders.mayors[0].avatar" :alt="leaders.mayors[0].name" class="leader-image"/>
            <span class="leader-name">{{ leaders.mayors[0].name }}</span>
            <span class="leader-position">{{ leaders.mayors[0].position }}</span>
          </div>
        </el-card>
      </el-col>

      <!-- 专职副书记 -->
      <el-col :span="6" v-for="(deputySecretary, index) in leaders.deputySecretaries.slice(0, 1)" :key="index"
              v-if="leaders.deputySecretaries && leaders.deputySecretaries.length > 0">
        <el-card class="box-card">
          <div class="leader-info" @click="handleClick(deputySecretary._id)">
            <img :src="deputySecretary.avatar" :alt="deputySecretary.name" class="leader-image"/>
            <span class="leader-name">{{ deputySecretary.name }}</span>
            <span class="leader-position">{{ deputySecretary.position }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-divider style="margin: 0 0 20px 0"></el-divider>

    <el-row :gutter="20">
      <!-- 副镇长 -->
      <el-col :span="6" v-for="(deputyMayor, index) in leaders.deputyMayors"
              :key="index + leaders.deputySecretaries.length"
              v-if="leaders.deputyMayors && leaders.deputyMayors.length > 0">
        <el-card class="box-card">
          <div class="leader-info" @click="handleClick(deputyMayor._id)">
            <img :src="deputyMayor.avatar" :alt="deputyMayor.name" class="leader-image"/>
            <span class="leader-name">{{ deputyMayor.name }}</span>
            <span class="leader-position">{{ deputyMayor.position }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <StaffDetails
      v-if="showStaffDetailsDialog"
      :id="selectedStaffDetailsId"
      @close="closeStaffDetailsDialog"
  />
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';
import StaffDetails from "@/views/Publicize/StaffDetails.vue";

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 响应式变量
const leaders = ref({
  mayors: [],
  deputyMayors: [],
  secretaries: [],
  deputySecretaries: []
});

// 定义 selectedStaffDetailsId 和 showStaffDetailsDialog 变量
const selectedStaffDetailsId = ref(null);
const showStaffDetailsDialog = ref(false);

// 定义 handleClick 方法
const handleClick = (id) => {
  selectedStaffDetailsId.value = id;
  showStaffDetailsDialog.value = true;
};

// 定义 closeStaffDetailsDialog 方法
const closeStaffDetailsDialog = () => {
  showStaffDetailsDialog.value = false;
  selectedStaffDetailsId.value = null;
};

// 获取领导人员数据
const fetchLeadersData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/publicize/leaders`);
    const formattedData = response.data.data;

    // 直接赋值后端返回的数据
    leaders.value.mayors = formattedData.mayors;
    leaders.value.deputyMayors = formattedData.deputyMayors;
    leaders.value.secretaries = formattedData.secretaries;
    leaders.value.deputySecretaries = formattedData.deputySecretaries;
  } catch (error) {
    console.error('获取领导人员数据失败:', error);
  }
};

// 在组件挂载时调用这些函数
onMounted(() => {
  fetchLeadersData();
});
</script>

<style scoped>
.container {
  background-color: #fdfdfd;
  padding: 20px;
}

.box-card {
  margin-bottom: 20px;
}

.leader-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.leader-image {
  width: 121px;
  height: 170px;
}

.leader-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.leader-position {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
}
</style>
