<!--部门人员详情-->
<template>
  <el-drawer
      v-model="dialogVisible"
      :direction="direction"
      size="60%"
      class="container"
  >
    <div class="staff-list">
      <div v-if="staffs.length === 0" class="noStaff container-style">
        <p>人都走光了，一会把他们找回来。</p>
      </div>
      <div v-for="staff in staffs" :key="staff._id" class="staff-item">
        <div
            @mouseenter="showLargeAvatar(staff.avatar)"
            @mouseleave="hideLargeAvatar"
        >
          <img
              :src="staff.avatar"
              :alt="staff.name"
              class="staff-avatar"
          />
        </div>

        <div class="staff-details">
          <h4>{{ staff.position }} {{ staff.name }}</h4>
          <el-divider></el-divider>
          <p>简历: {{ staff.biography }}</p>
          <el-divider></el-divider>
          <p>职责: {{ staff.responsibility }}</p>
        </div>
      </div>
    </div>
    <!-- 大头像模态框 -->
    <img
        :src="largeAvatar"
        alt="Large Avatar" class="large-avatar container-style"
        v-if="largeAvatarVisible"
    >
  </el-drawer>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
  modelValue: Boolean,
  name: String
});

const emit = defineEmits(['update:modelValue']);

// 根据 props.modelValue 初始化 dialogVisible
const dialogVisible = ref(props.modelValue);
const staffs = ref([]);
const largeAvatarVisible = ref(false);
const largeAvatar = ref('');
const direction = ref('ltr'); // 设置抽屉从右向左打开

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 获取员工信息
const fetchStaffInfo = async (name) => {
  if (name) {
    try {
      const response = await axios.get(`${baseUrl}/webapi/department/staffs/${name}`);
      staffs.value = response.data.data;
    } catch (error) {
      console.error('获取人员信息失败:', error);
      staffs.value = [];
    }
  }
};

// 显示大头像
const showLargeAvatar = (avatar) => {
  largeAvatar.value = avatar;
  largeAvatarVisible.value = true;
};

// 隐藏大头像
const hideLargeAvatar = () => {
  largeAvatarVisible.value = false;
};

// 监听 props.name 的变化，并获取员工信息
watch(
  () => props.name,
  fetchStaffInfo,
  { immediate: true }
);

// 监听 props.modelValue 的变化，并更新 dialogVisible
watch(
  () => props.modelValue,
  (newValue) => {
    dialogVisible.value = newValue;
  }
);

// 在组件挂载时获取数据
onMounted(fetchStaffInfo);
</script>

<style lang="scss" scoped>
.container {
  background-color: #fdfdfd;
  .staff-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

  }

  .staff-item {
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
  }

  .staff-avatar {
    width: 121px;
    height: 170px;
    object-fit: cover;
    margin-right: 20px;
    cursor: pointer; /* 添加鼠标指针样式 */
  }

  .staff-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  h4 {
    margin: 0 0 10px 0;
    font-size: 15px;
  }

  p {
    margin: 0 0 10px 0;
    font-size: 15px;
    line-height: 1.8;
  }

  .el-divider--horizontal {
    margin: 10px 0;
  }

  .noStaff {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
  }
}

.large-avatar {
  position: fixed; /* 固定位置 */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* 确保在最上层 */
  width: 413px;
  height: 579px;
  object-fit: contain; /* 保持图片比例 */
}
</style>
