<!--分页组件-->
<template>
  <el-pagination
      background
      layout="pager,slot,jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      class="pagination"
  >
    <template #default>
      <div class="custom-button">
        <button class="move-left" @click="goToFirstPage" :disabled="currentPage === 1" :style="{ left: lastLeft }">
          首页
        </button>
        <button @click="goToLastPage" :disabled="currentPage === totalPages">末页</button>
        <span>共 {{ totalPages }} 页</span>
      </div>
    </template>
  </el-pagination>
</template>

<script setup>
import {computed} from 'vue';

const props = defineProps({
  total: {
    type: Number,
    required: true
  },
  pageSize: {
    type: Number,
    required: true
  },
  currentPage: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['update:currentPage']);

const totalPages = computed(() => {
  return Math.ceil(props.total / props.pageSize);
});

const lastLeft = computed(() => {
  return `${0 -(totalPages.value * 36 + 52)}px`;
});

const handleCurrentChange = (newPage) => {
  emit('update:currentPage', newPage);
};

const goToFirstPage = () => {
  emit('update:currentPage', 1);
};

const goToLastPage = () => {
  emit('update:currentPage', totalPages.value);
};
</script>

<style scoped>
.pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  ::v-deep(.el-pager li) {
    width: 30px;
    height: 30px;
    margin: 0 2px !important;

    border: 1px solid #d1d1d1; /* 添加边框 */
    border-radius: 3px; /* 圆角 */
  }

  ::v-deep(.el-select__wrapper) {
    width: 64px;
    padding: 4px 6px;
  }

  ::v-deep(.el-input__wrapper) {
    height: 30px;
    padding: 0;
  }

  .custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606266;
    position: relative;

    button {
      width: 48px;
      height: 30px;
      margin: 0 8px 0 2px;
      background-color: transparent;
      border-radius: 3px;
      border: 1px solid #d1d1d1;

      &:hover {
        background-color: #e1e2e2;
        transition: background-color 0.3s ease;
      }
    }

    .move-left {
      position: absolute; /* 绝对定位 */
    }
  }
}
</style>
