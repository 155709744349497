<!--导航组件，主页调用-->
<template>
  <div :class="['navbar', { 'navbar-big': isBig, 'navbar-small': !isBig }]">
    <el-menu
        :default-active="route.fullPath"
        :router="true"
        class="el-menu-demo"
        mode="horizontal"
    >
      <el-menu-item index="/">网站首页</el-menu-item>
      <el-menu-item index="/public">政务公开</el-menu-item>
      <el-menu-item index="/pioneer">先锋圳上</el-menu-item>
      <el-menu-item index="/service">办事服务</el-menu-item>
      <el-menu-item index="/media">新闻中心</el-menu-item>
      <el-menu-item index="/gozsz">纯朴圳上</el-menu-item>
      <el-menu-item class="right-align" @click="scrollToAnchor">关于我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import {computed, ref} from "vue";

const router = useRouter();
const route = useRoute();

// 定义 props
const props = defineProps({
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value)
  }
});

// 计算属性，根据路由名称决定 navbar 的大小
const isBig = computed(() => route.name === 'home-default' || props.size === 'big');

// 使用 ref 来引用锚点元素
const aboutAnchor = ref(null);

// 滚动到锚点的方法
const scrollToAnchor = () => {
  if (route.path === '/') {
    aboutAnchor.value = document.getElementById('about-anchor');
    aboutAnchor.value.scrollIntoView({behavior: 'smooth'});
  } else {
    router.push('/about-us');
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  &-big {
    background: rgba(68, 156, 243, 1);

    .el-menu-demo {
      width: 1200px;
      background-color: transparent;
      margin: 0 auto;

      .el-menu-item {
        font-size: 22px;
        color: white;

        &:hover {
          color: #c72c1a !important; // 鼠标移入时文字颜色为红色
          background-color: transparent;
        }

        &.is-active {
          color: #c72c1a !important; // 选中项文字颜色为红色
        }
      }
    }

    .right-align {
      position: absolute;
      right: 0;
    }
  }

  &-small {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(68, 156, 243, 1));

    .el-menu-demo {
      width: 1200px;
      height: 49px;
      background-color: transparent;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      border-bottom: none;

      .el-menu-item {
        font-size: 17px;
        font-weight: bold;
        color: white;
        padding: 0 10px;

        &:hover {
          color: #c72c1a !important; // 鼠标移入时文字颜色为红色
          background-color: transparent;
        }

        &.is-active {
          color: #c72c1a !important; // 选中项文字颜色为红色
        }
      }
    }
  }
}
</style>
