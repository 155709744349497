<!--圳上人文-->
<template>
  <div class="overview-container">
    <p class="preserve-newlines" v-html="formattedOverview"></p>
    <div class="accordion-container">
      <AccordionItem
          title="陈正湘"
          :contentPart1="content1Part1"
          :contentPart2="content1Part2"
          :imageUrl="imageUrl1"
          :imageCaption="imageCaption1"
      />
      <AccordionItem
          title="方鼎英"
          :contentPart1="content2Part1"
          :contentPart2="content2Part2"
          :imageUrl="imageUrl2"
          :imageCaption="imageCaption2"
      />
      <AccordionItem
          title="罗盛教"
          :contentPart1="content3Part1"
          :contentPart2="content3Part2"
          :imageUrl="imageUrl3"
          :imageCaption="imageCaption3"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AccordionItem from '@/components/AccordionItem.vue';

const data = {
  overview:
      "圳上镇，不仅自然风光旖旎，山川秀美，更是人文荟萃之地。\n" +
      "山的雄伟、水的灵性和乡里人的纯朴，使这里孕育了一代又一代名震中外的风云人物。原黄埔军校教育长、代校长方鼎英，华夏名将陈正湘，国际共产主义战士罗盛教，生于斯，长于斯。"
};

// 处理字符串，在每一行前增加两个中文空格
const formattedOverview = data.overview.split('\n').map(line => '　　' + line).join('<br>');

const content1Part1 = ref("　　华夏名将陈正湘，忠诚的共产主义战士、人民解放军名将、北京军区副司令员，县圳上镇人，清宣统三年（1911年）十月出生。陈正湘幼时家贫，民国十五年（1926年）入唐生智部当兵，参加北伐，后入滇军朱培德部。民国十九年（1930年）驻江西大余，联合士兵击毙作恶多端的连长后起义，参加陈毅部红二十二军，次年加入中国共产党。先后在红三十五师，红一军团一师、五师、少共国际师任连长、营长、团长等职，参加了中央苏区第一至第五次反“围剿”战役。战斗中英勇无畏，获中央军委三等红星奖章。抗日战起，陈正湘任八路军一一五师副团长，参与了平型关反击日寇之战。旋调任晋察冀军区团长，率部在山西、河北等地开展抗日游击战。");
const content1Part2 = ref("　　民国二十八年（1939年）11月，在涞源县雁宿崖、黄土岭与日军激战，指挥炮兵击毙日军独立混成第二旅团长阿部规秀。朝日新闻发表标题为《名将之花凋落在太行山上》的新闻。后任第一支队司令员，第四、第十一军分区司令员。解放战争时期，任冀晋军区副司令员。晋察冀第四纵队司令员，第二纵队司令员。中华人民共和国成立后，任中国人民解放军铁道兵团第一副司令员，华北军区副参谋长，北京军区副司令员。1955年被授予中将军衔。是第四届全国人民代表大会代表，中国人民政治协商会议第三届全国委员会委员，第五届全国委员会常务委员，中国共产党第七次全国代表大会候补代表。1993年病逝北京，骨灰运回圳上镇安葬。并建亭纪念，命名为“将军亭”。");
const imageUrl1 = ref('/gozsz/陈正湘.jpg');
const imageCaption1 = ref("陈正湘(1911-1993)");

const content2Part1 = ref("　　爱国名将方鼎英，号伯雄，县时雍团富溪村（今圳上镇方家村）人，清光绪十四年（1888年）生。十四岁赴日本求学，入振武学堂、陆军士官学校习军事。在日期间，先后加入反清爱国组织求知社、文学社，后加入同盟会。回国后，任保定军校教官。辛亥革命时，参加保卫汉阳之战，民国六年（1917年），再次赴日本攻修炮兵专业。民国十二年（1930年），孙中山命湘军组织讨贼军讨伐湘督赵恒惕，方鼎英代理军长，在湘江西岸与敌军激战三十余日，终于挽回危局。旋又奉孙中山命，平陈炯明叛乱，解广州之危。");
const content2Part2 = ref("　　民国十四年（1925年）起，先后任黄埔军校入伍生部中将部长、教育长、代校长等职，危难时，掩护共产党人离校，保护了革命力量。民国16年秋，历任暂编第十三军军长、四十六军军长、第一集团军第三军团总指挥，西征军第一路总指挥等军事要职后，深感内战频仍，民无宁日，乃离开军队幽居上海。抗日时期，力主抗日，曾出任第九战区战地党政委员会副主任委员。抗战胜利后，联合国民党革命派人士从事反内战活动，赞成湖南程潜陈明仁和平起义。解放后，曾出任湖南省人民委员会委员、参事室主任、司法厅长，全国政协第二、三、四届委员等职。1976年病逝长沙。");
const imageUrl2 = ref('/gozsz/方鼎英.jpg');
const imageCaption2 = ref("方鼎英(1888-1976)");

const content3Part1 = ref("　　罗盛教（1931—1952）国际主义战士。原名雨成，县下庙乡（今圳上镇松山）人，民国二十年（1931年）生。幼家贫辍学，依叔父在乾城（今吉首）读书。后考入长沙第十三中学，改名盛教。1949年10月，在沅陵参加中国人民解放军，在第47军141师任侦察连文书，随即去湘西剿匪，在战斗中立功一次。1951年，随部参加抗美援朝入朝作战。目睹美国侵略军残杀朝鲜人民惨状，义愤填膺，立誓要为朝鲜人民和牺牲的战友报仇。1952年1月2日，在朝鲜平安道成川郡石田里为抢救跌入冰窟的朝鲜少年崔滢光荣殉职。朝鲜最高人民会议常任委员会追授予罗盛教一级国旗勋章，并在佛体峒山建纪念亭和纪念碑，金日成主席题“罗盛教烈士的国际主义精神与朝鲜人民永远共存”。新化县在城东资水滨建纪念馆，以资永念。");
const content3Part2 = ref("");
const imageUrl3 = ref('/gozsz/罗盛教.jpg');
const imageCaption3 = ref("罗盛教(1931-1952)");
</script>



<style scoped>
.overview-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
}

.preserve-newlines {
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 2;
  color: #333;
}

.accordion-container {
  margin-top: 20px;
}
</style>
