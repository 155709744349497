<!--今日关注-->
<template>
  <el-container>
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item @click="router.go(-1)">
          <div class="breadcrumb-item-content">
            <el-icon class="back">
              <Back/>
            </el-icon>
            <span class="text">首页</span>
          </div>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ currentBreadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>

    <el-container>
      <el-aside>
        <el-menu @select="handleMenuChange">
          <el-menu-item v-for="menu in menuList" :key="menu.name" :index="String(menu.name)">
            <span>{{ menu.label }}</span>
            <el-icon>
              <ArrowRight/>
            </el-icon>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-main>
        <el-row class="timeline">
          <el-col :span="24">
            <el-timeline v-if="displayedNews.length">
              <el-timeline-item
                  v-for="(data, index) in paginatedNews"
                  :key="index"
              >
              <span class="newsTitle pointer" @click="handleChangePage(data._id)">
                {{ data.publicationTime.substring(0, 10) }}　　　{{ data.title }}
              </span>
              </el-timeline-item>
            </el-timeline>
            <p v-else>暂无新闻</p>
          </el-col>
        </el-row>

        <el-row class="pagination" v-if="displayedNews.length">
          <el-col :span="24">
            <!-- 使用 Pagination 组件 -->
            <Pagination
                :total="displayedNews.length"
                :page-size="pageSize"
                :current-page="currentPage"
                @update:currentPage="handlePageChange"
            />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';
import {ArrowRight, Back} from '@element-plus/icons-vue'; // 引入 ArrowRight 图标
import Pagination from '@/components/Pagination.vue';
import {ElBreadcrumb, ElBreadcrumbItem, ElIcon} from "element-plus"; // 引入 Pagination 组件

const router = useRouter();
const newlist = ref([]);
const item = ref(0); // 初始值为 0，表示默认选中“今日关注”
const currentBreadcrumb = ref('今日关注'); // 新增当前面包屑显示
const pageSize = ref(10); // 每页显示的新闻数量
const currentPage = ref(1); // 当前页码

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const menuList = [
  {label: '今日关注', name: 0},
  {label: '最新动态', name: 1},
  {label: '纪检监察', name: 2},
  {label: '通知公告', name: 3},
  {label: '先锋圳上', name: 4},
  {label: '工作动态', name: 5},
  {label: '政府工作报告', name: 6},
];

const menuNews = computed(() => _.groupBy(newlist.value, item => item.category));

const displayedNews = computed(() => {
  if (item.value === 0) {
    return newlist.value; // 默认显示所有新闻
  } else {
    return menuNews.value[item.value] || [];
  }
});

const paginatedNews = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return displayedNews.value.slice(start, end);
});

const handleChangePage = (id) => {
  const currentTitle = menuList.find(menu => menu.name === item.value)?.label || '未知';

  router.push({
    path: `/new/${id}`,
    query: {parentTitle: currentTitle}
  });
};

const handleMenuChange = (index) => {
  item.value = parseInt(index);
  currentBreadcrumb.value = menuList.find(menu => menu.name === parseInt(index))?.label || '未知';
  currentPage.value = 1; // 切换菜单时重置页码
};

const handlePageChange = (newPage) => {
  currentPage.value = newPage;
};

onMounted(async () => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/news/list`);
    newlist.value = res.data.data;
  } catch (error) {
    console.error('获取新闻列表失败:', error);
  }
});
</script>

<style lang="scss" scoped>
.el-header {
  width: 100%;
  height: 50px;
  background-color: #cee2f5;

  .breadcrumb-item-content {
    display: flex;
    align-items: center;

    .back {
      margin-right: 10px;
    }

    .text {
      font-size: 15px;
      color: #414141;
    }
  }

  .el-breadcrumb {
    font-size: 16px;
    padding-top: 20px;
  }
}

.el-aside {
  width: 220px;
  padding: 20px;
  background: linear-gradient(to bottom, #fdfdfd, rgba(255, 255, 255, 0));

  .el-menu {
    border-right: none; /* 去掉默认的右边框 */
    background-color: transparent;
    padding: 10px;

    .el-menu-item {
      font-size: 16px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      margin-bottom: 6px;
      padding: 0 5px 0 20px;
      height: 49px;
      background-color: #f5f5f5;

      &:hover {
        background-color: #e1e2e2;
        transition: background-color 0.3s ease;
      }

      &.is-active .el-icon {
        color: #3f9dfd; /* 选中时图标颜色为蓝色 */
        transition: color 0.3s ease;
      }
    }

    .el-icon {
      margin-left: 10px;
      color: transparent; /* 默认图标颜色为透明 */
    }
  }
}

.el-main {
  width: 100%;
  height: 549px;
  padding: 20px 20px 0;
  background: linear-gradient(to bottom, #e4e4e4, rgba(255, 255, 255, 0));

  .timeline {
    height: 526px;

    .newsTitle {
      font-size: 16px;
    }
  }

}
</style>
