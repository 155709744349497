<!--部门领导人员-->
<template>
  <div class="container">
    <!-- 部门图标和部门名称 -->
    <div class="department-info">
      <div class="icon-container">
        <img :src="departmentIcon" :alt="departmentName" class="department-icon"/>
      </div>
      <div class="name-container">
        <h3 class="department-name">{{ departmentName }}</h3>
      </div>
    </div>

    <el-divider style="margin: 0 0 20px 0"></el-divider>

    <el-row :gutter="20">
      <el-col :span="6" v-for="(leader, index) in leaders"
              :key="index"
              v-if="leaders.length > 0">
        <el-card class="box-card">
          <div class="leader-info" @click="handleClick(leader)">
            <img :src="leader.avatar" :alt="leader.name" class="leader-image"/>
            <span class="leader-name">{{ leader.name }}</span>
            <span class="leader-position">{{ leader.position || '　'}}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <span class="name-sort">按姓名排序</span>
  </div>
  <StaffDetails
      v-if="showDialog"
      :id="selectedId"
      @close="closeDialog"
  />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'; // 引入 watch
import axios from 'axios';
import StaffDetails from "@/views/Publicize/StaffDetails.vue";

// 定义 props
const props = defineProps({
  category: {
    type: [String, Number],
    required: true
  }
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 响应式变量
const leaders = ref([]);
const selectedId = ref(null);
const showDialog = ref(false);
const departmentIcon = ref('');
const departmentName = ref('');

// 静态部门数据
const departments = [
  { category: 1, name: '圳上镇党委', icon: '/publicize/党委.png' },
  { category: 2, name: '圳上镇政府', icon: '/publicize/政府.png' },
  { category: 3, name: '圳上镇人大', icon: '/publicize/人大.png' },
  { category: 4, name: '圳上镇纪委', icon: '/publicize/纪委.png' },
  { category: 5, name: '圳上镇人武部', icon: '/publicize/人武部.png' }
];

// 定义 handleClick 方法
const handleClick = (leader) => {
  // 假设 leader 包含 _id 属性
  selectedId.value = leader._id;
  showDialog.value = true;
};

// 定义 closeDialog 方法
const closeDialog = () => {
  showDialog.value = false;
  selectedId.value = null;
};

// 获取部门领导人员数据
const fetchLeadersData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/department/leaders/${props.category}`);
    const data = response.data.data;

    // 清空原有数据
    leaders.value = [];

    // 填充数据
    data.forEach(leader => {
      leaders.value.push({
        _id: leader._id,
        avatar: leader.avatar,
        name: leader.name,
        position: leader.position
      });
    });

    // 设置部门的图标和名称
    const department = departments.find(dept => dept.category === props.category);
    if (department) {
      departmentIcon.value = department.icon;
      departmentName.value = department.name;
    }
  } catch (error) {
    console.error('获取领导人员数据失败:', error);
  }
};

// 在组件挂载时调用这些函数
onMounted(() => {
  fetchLeadersData();
});

// 监听 category 的变化
watch(
    () => props.category,
    () => {
      fetchLeadersData(); // 重新获取数据
    }
);
</script>

<style lang="scss" scoped>
.container {
  background-color: #fdfdfd;
  padding: 18px 20px 13px 20px;
}

.box-card {
  margin-bottom: 20px;
}

.leader-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.leader-image {
  width: 121px;
  height: 170px;
}

.leader-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.leader-position {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
}

.name-sort {
  display: block;
  margin: 0 auto;
  font-size: 14px;
  color: #e8e8e8;
  width: fit-content;
}

.department-info {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.department-icon {
  width: auto;
  height: 50px;
}

.department-name {
  margin: 0;
}
</style>
