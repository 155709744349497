<!--顶部公用模块，主页调用-->
<template>
  <header :class="['header', { 'big-header': isBig, 'small-header': !isBig }]" :style="headerStyle">
    <div class="logo-container">
      <img alt="" class="center_logo" :src="logoSrc">
    </div>

    <!-- 搜索框 -->
    <Search v-if="isBig" />
  </header>
</template>

<script setup>
import { computed } from 'vue';
import Search from '@/views/Home/Search.vue';

// 定义 props
const props = defineProps({
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value)
  }
});

// 计算属性，根据传入的 size 决定 header 的大小
const isBig = computed(() => props.size === 'big');

// 根据 header 大小选择不同的 logo 图片
const bigLogo = '/home/header/zszis.big.logo.png';
const smallLogo = '/home/header/zszis-small.logo.png';

const logoSrc = computed(() => {
  return isBig.value ? bigLogo : smallLogo;
});

// 计算属性，根据 header 大小选择不同的背景图片
const bigBackground = '/home/header/background.jpg';
const smallBackground = '/home/header/background.jpg';

const headerStyle = computed(() => {
  return {
    backgroundImage: `url(${isBig.value ? bigBackground : smallBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
});
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: relative; // 使伪元素生效

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px; // 渐变透明框的高度
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0) 100%
    ); // 反向渐变透明效果
    z-index: 1;
  }

  &.big-header {
    height: 448px;

    &::after {
      height: 60px; // 渐变透明框的高度
    }

    .logo-container {
      padding: 42px 0 20px;
      text-align: center;
    }
  }

  &.small-header {
    height: 210px;

    &::after {
      height: 40px;
    }

    .logo-container {
      padding: 80px calc((100% - 1200px) / 2) 43px;
    }
  }
}
</style>
