import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Particles from "particles.vue3"
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import VueLazyload from 'vue-lazyload';

const app = createApp(App)

app.use(Particles)
app.use(ElementPlus, { locale: zhCn });
app.use(store)
app.use(router)

// 懒加载
app.use(VueLazyload, {
    preLoad: 1.3,
    error: 'path/to/error.png',
    loading: '/loading.gif',
    attempt: 1
});

app.mount('#app')

