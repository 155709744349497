<!--主页-->
<template>
  <div class="carousel-box">
    <!-- 轮播图 -->
    <Carousel :width="carouselWidth" :height="carouselHeight" :images="carouselItems"/>
    <!-- 新闻列表 -->
    <SlideTab/>
  </div>

  <!-- 热点专题 -->
  <section class="hot-topics">
    <h4>热点专题</h4>
    <div class="topics-grid">
      <div v-for="(topic, index) in topics" :key="index" class="topic-item container-style">
        <a :href="topic.link" @click.prevent="handleClick(topic)">
          <img :alt="topic.title" :src="topic.image"/>
          <p v-text="topic.title"></p>
        </a>
      </div>
    </div>
  </section>

  <section class="frequently-used-links">
    <h4>办公常用网址链接</h4>
    <FrequentlyUsedLinks/>
  </section>

  <!-- 关于我们 -->
  <AboutUs id="about-anchor" ref="aboutAnchorRef"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import Carousel from "@/components/Carousel.vue";
import AboutUs from "@/components/AboutUs.vue";
import SlideTab from "@/views/Home/SlideTab.vue";
import FrequentlyUsedLinks from "@/views/Home/FrequentlyUsedLinks.vue";
import axios from "axios";
import {useRouter} from "vue-router";

const router = useRouter(); // 初始化 router

const carouselWidth = "590px";
const carouselHeight = "362px";

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 轮播图片数据
const carouselItems = ref([]);

// 获取轮播图数据
const fetchCarouselData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/carousel/list/home`);
    const data = response.data.data;

    // 合并图片地址和图片标题
    carouselItems.value = data.map(item => ({
      src: item.cover,
      title: item.title
    }));
  } catch (error) {
    console.error("Error fetching carousel data:", error);
    alert("无法获取轮播图数据，请稍后再试。");
  }
};

// 定义热点专题数据
const topics = ref([]);

// 获取热点专题数据
const fetchHotTopicsData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/hot/topics`);
    const data = response.data.data;

    // 处理数据格式
    topics.value = data.map(item => ({
      image: item.cover, // 确保 item.cover 存在
      title: item.title,
      link: item.category,
    }));
  } catch (error) {
    console.error("Error fetching hot topics data:", error);
    alert("无法获取热点专题数据，请稍后再试。");
  }
};

// 点击事件处理
const handleClick = (topic) => {
  router.push({
    name: 'newsHot', // 使用命名路由
    query: {link: topic.link, title: topic.title}
  });
};

// 在组件挂载时获取数据
onMounted(() => {
  fetchCarouselData();
  fetchHotTopicsData(); // 添加热点专题数据获取
});
</script>

<style lang="scss" scoped>
.carousel-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hot-topics {
  width: 100%;
  margin: 30px 0;

  .topics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 三列布局
    gap: 20px;

    .topic-item {
      text-align: center;

      a {
        display: inline-block;
        width: 100%;

        img {
          width: 100%;
          height: 192px;
          border-radius: 6px 6px 0 0; // 左上角和右上角圆角
        }

        p {
          height: 28px;
          line-height: 28px;
          font-size: 15px;
        }
      }
    }
  }
}

h4 {
  margin-bottom: 10px;
  color: #8c1a1a;
}

.link {
  width: 100%;
  height: 300px;
  margin: 30px 0;
  background: #00B7FF;
  text-align: center;
  line-height: 300px;
  color: #fff;
  font-size: 24px;
}
</style>
