<!--历史沿革-->
<template>
  <div class="overview-container">
    <p class="preserve-newlines" v-html="formattedOverview"></p>
  </div>
</template>

<script setup>
const data = {
  overview: "明代，属新化县石马一都、二都。\n" +
      "清代，属时雍团。\n" +
      "民国时期，属时雍乡富溪、上庙、下庙、锡溪、楚良五村。\n" +
      "1950年，属新化县第七区（后改为第十八区）圳上、山溪、松山、半山、永镇、龙驹、海南七乡。\n" +
      "1956年，设圳上办事处，下设圳上、山溪、半山、金龙四乡。\n" +
      "1958年，圳上办事处改公社。\n" +
      "1961年，设圳上区，下设圳上、山溪、松山、半山、株梓、海龙6个公社\n" +
      "1984年，圳上公社改镇，圳上区下设圳上镇和山溪、松山、半山、株梓、海龙五乡。\n" +
      "1995年，撤区并镇，山溪、松山、半山、株梓、海龙5乡并入圳上镇。\n" +
      "截至2024年10月，圳上镇辖3个社区、40个行政村，镇人民政府驻鼎英村。"
};

// 处理字符串，在每一行前增加两个中文空格
const formattedOverview = data.overview.split('\n').map(line => '　　' + line).join('<br>');
</script>

<style scoped>
.overview-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
}

.preserve-newlines {
  white-space: pre-wrap; /* 保留换行符并允许文本换行 */
  font-size: 16px;
  line-height: 2;
  color: #333;
}
</style>
