<!-- AddressSelector.vue -->
<template>
  <div class="addressSelector">
    <el-select v-model="selectedProvince" placeholder="请选择省份" @change="onProvinceChange" class="select-item">
      <el-option
          v-for="province in provinces"
          :key="province.code"
          :label="province.province"
          :value="province.code"
      />
    </el-select>
    <el-select v-model="selectedCity" placeholder="请选择城市" @change="onCityChange" :disabled="!selectedProvince"
               class="select-item">
      <el-option
          v-for="city in cities"
          :key="city.code"
          :label="city.city"
          :value="city.code"
      />
    </el-select>
    <el-select v-model="selectedDistrict" placeholder="请选择区县" :disabled="!selectedCity" class="select-item">
      <el-option
          v-for="district in districts"
          :key="district.code"
          :label="district.area"
          :value="district.code"
      />
    </el-select>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

const emit = defineEmits(['select-address']);

const selectedProvince = ref('');
const selectedCity = ref('');
const selectedDistrict = ref('');
const provinces = ref([]);
const cities = ref([]);
const districts = ref([]);

const props = defineProps({
  province: String,
  city: String,
  county: String
});

// 加载省份数据
const loadProvinces = async () => {
  try {
    const response = await fetch('/ChinaCitys.json');
    const data = await response.json();
    provinces.value = data.map(province => ({
      code: province.code,
      province: province.province,
      citys: province.citys || []
    }));
  } catch (error) {
    console.error('Failed to load provinces:', error);
  }
};

// 根据省份加载城市数据
const loadCities = (provinceCode) => {
  const province = provinces.value.find(p => p.code === provinceCode);
  if (province && province.citys) {
    cities.value = province.citys.map(city => ({
      code: city.code,
      city: city.city,
      areas: city.areas || []
    }));
  } else {
    cities.value = [];
  }
};

// 根据城市加载区县数据
const loadDistricts = (cityCode) => {
  const city = cities.value.find(c => c.code === cityCode);
  if (city && city.areas && city.areas.length > 0) {
    districts.value = city.areas.map(area => ({
      code: area.code,
      area: area.area
    }));
  } else {
    districts.value = [];
  }
};

// 模糊匹配函数
const fuzzyMatch = (list, key, value) => {
  return list.find(item => item[key].startsWith(value));
};

// 监听省份和城市的改变以加载相应的城市和区县
watch([selectedProvince, selectedCity], ([newProvince, newCity]) => {
  if (newProvince) {
    loadCities(newProvince);
  }
  if (newCity) {
    loadDistricts(newCity);
  }
});

// 监听区县的选择并截取前六位区域码
watch(selectedDistrict, (newDistrict) => {
  if (newDistrict) {
    const shortCode = newDistrict.substring(0, 6); // 截取前六位

    // 获取完整的地址信息
    const selectedProvinceObj = provinces.value.find(p => p.code === selectedProvince.value);
    const selectedCityObj = cities.value.find(c => c.code === selectedCity.value);
    const selectedDistrictObj = districts.value.find(d => d.code === selectedDistrict.value);

    const fullAddress = {
      province: selectedProvinceObj ? selectedProvinceObj.province : '',
      city: selectedCityObj ? selectedCityObj.city : '',
      district: selectedDistrictObj ? selectedDistrictObj.area : ''
    };

    // 将区域码和完整地址信息一起返回给父组件
    emit('select-address', { shortCode, fullAddress });
  }
});

// 省份改变时重置城市和区县选择
const onProvinceChange = (value) => {
  selectedCity.value = '';
  selectedDistrict.value = '';
  loadCities(value);
};

// 城市改变时重置区县选择
const onCityChange = (value) => {
  selectedDistrict.value = '';
  loadDistricts(value);
};

// 组件挂载后加载省份数据并设置默认值
onMounted(async () => {
  await loadProvinces();

  // 设置默认值
  if (props.province) {
    const province = provinces.value.find(p => p.province === props.province);
    if (province) {
      selectedProvince.value = province.code;
      loadCities(province.code);

      if (props.city) {
        const city = cities.value.find(c => c.city === props.city);
        if (city) {
          selectedCity.value = city.code;
          loadDistricts(city.code);

          if (props.county) {
            // 使用模糊匹配来找到最匹配的区县
            const district = fuzzyMatch(districts.value, 'area', props.county);
            if (district) {
              selectedDistrict.value = district.code;
            }
          }
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.addressSelector {
  display: flex;
  align-items: center;
  gap: 20px;

  .select-item {
    width: 175px;
  }

  .location-icon {
    font-size: 20px;
    color: #409EFF;
  }
}
</style>
