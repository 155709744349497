<!--自然地理-->
<template>
  <div class="overview-container">
    <h3>地形地貌</h3>
    <p>　　圳上镇地势东南、西北两侧高，向西倾斜。境内最高点位于九龙峰，海拔1622米，最低点位于锡溪塘，海拔253米。圳上镇毗邻大熊山国家森林公园，是进入大熊山这座自然宝库的主要门户。
    </p>
    <img src="/gozsz/圳上镇地形图01.webp" alt="">
    <img src="/gozsz/圳上镇地形图02.webp" alt="">
    <img src="/gozsz/圳上镇卫星地貌图.webp" alt="">
  </div>
</template>

<script setup>

</script>

<style scoped>
.overview-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 2;

  img {
    padding: 30px;
    }
}
</style>
