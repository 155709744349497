<!--底部区域，显示网站批文、版权-->
<template>
  <!-- 底部区域 -->
  <footer class="footer">
    <a href="https://beian.miit.gov.cn" target="_blank">
      {{ icp }}&nbsp;&nbsp;&nbsp;&nbsp;版权所有 ©&nbsp;&nbsp;{{ copyright }}&nbsp;&nbsp;&nbsp;&nbsp;{{ writer }}&nbsp;&nbsp;&nbsp;&nbsp;保留所有权利。
    </a>
  </footer>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 初始化默认值
const icp = ref('湘ICP备2024081509号-1');
const copyright = ref('版权所有 © 2022-2024');
const writer = ref('e代农夫');

// 加载数据
onMounted(async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/systemInfo/list`);
    if (response.data.ActionType === "OK") {
      const data = response.data.data[0] || {};
      icp.value = data.icp || icp.value;
      copyright.value = data.copyright || copyright.value;
      writer.value = data.writer || writer.value;
    } else {
      console.error("获取数据失败，请检查网络或稍后再试！");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 40px;
  border-top: 3px solid rgb(68, 156, 243);
  background: #e6e6e6;

  a {
    display: block;
    width: 1200px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    color: #666666;
  }
}
</style>
