<!--先锋圳上模块-->
<template>
  <el-carousel
      :interval="4000"
      type="card"
      height="360px"
      autoplay
  >
    <el-carousel-item v-for="(image, index) in images" :key="index">
      <img :src="image" alt="carousel image" style="width: 100%; height: 100%; object-fit: cover;">
    </el-carousel-item>
  </el-carousel>

  <div class="container">
    <h4 style="color: #3d3d3d">更多内容，敬请期待......</h4>
    <h3 style="padding-top: 20px;font-style: italic;">更期待您的创意</h3>
    <h2 style="padding-top: 20px;color: #3d3d3d">您的梦想，我来实现</h2>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 定义图片路径数组
const images = ref([]);

/// 在组件挂载时请求图片列表
onMounted(async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/carousel/list/pioneer`);
    if (response.data.ActionType === "OK") {
      images.value = response.data.data.map(item => item.cover); // 提取 cover 字段
    } else {
      console.error('Unexpected response:', response.data);
    }
  } catch (error) {
    console.error('Error fetching carousel images:', error);
  }
});
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.container {
  margin-top: 40px;
  text-align: center;
}
</style>
