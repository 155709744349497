<template>
  <div class="homeLink container-style">
    <div v-for="(link, index) in links" :key="index" class="link" @click="openLink(link)">
      {{ link.label }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// 定义网址链接数组，个人服务
const links = ref([
  {
    "label": "湖南省村级财务统一软件管理平台",
    "website": "http://59.231.9.182:6005/sfdw/homepage"
  },
  {
    "label": "全国农村集体资产监督管理平台",
    "website": "https://jtzcjg.moa.gov.cn/user/#/login"
  },
  {
    "label": "全国家庭农场名录系统",
    "website": "https://jtncml.moa.gov.cn/login.html"
  },
  {
    "label": "农业农村数据采集平台",
    "website": "http://nyxxcj.agri.gov.cn/hzjj/platform/login"
  },
  {
    "label": "湖南省农村集体产权管理信息平台",
    "website": "https://www.hnncsz.com/#/login?redirect=%2Fhome"
  },
  {
    "label": "湖南省“互联网 + 监督”统一登录平台",
    "website": "http://59.231.9.97/home.do"
  },
  {
    "label": "湖南省“互联网 + 监督”(外网)",
    "website": "http://hlwjjd.hunan.gov.cn/portal/portalIndex"
  },
  {
    "label": "湖南省互联网＋政务服务一体化平台",
    "website": "http://59.231.9.227:8089/zwfwgzmh/index.htm"
  },
  {
    "label": "湖南省社会保障费申报测算管理系统",
    "website": "http://hntrapp.hunan.chinatax.gov.cn:20001/tax-corp-web/#/login"
  },
  {
    "label": "湖南省政府采购电子卖场",
    "website": "https://hunan.zcygov.cn"
  },
  {
    "label": "湖南省网上工会工作平台",
    "website": "https://ghpt.hnszgh.org.cn/server/#/login"
  },
  {
    "label": "财政预决算公开管理平台",
    "website": "http://yjs.jbreport.com/manager/login"
  },
  {
    "label": "湖南省电子税务局",
    "website": "https://etax.hunan.chinatax.gov.cn/wsbs/toLogin.do?sbxxOper=Y"
  },
  {
    "label": "湖南省新化县人民政府网",
    "website": "https://www.xinhua.gov.cn/"
  },
  {
    "label": "如法网·学法入口",
    "website": "http://hn.12348.gov.cn/ucenter/#/login?redirect=http%3A%2F%2Fhn.12348.gov.cn%2Ffxmain%2Fstudy%3Fbiz%3Dfxmain"
  },
  {
    "label": "如法网·考法入口",
    "website": "http://hn.12348.gov.cn/rufawebsite/examination/content/navigate"
  },
  {
    "label": "机关事业单位职业培训",
    "website": "https://ggfw.rst.hunan.gov.cn/hrss-pw-ui-hunan/#/login"
  },
  {
    "label": "全国公休节假日日历",
    "website": "https://wannianli.tianqi.com/"
  },
  {
    "label": "你的专属链接，等待你添加......",
    "website": ""
  },
])

// 打开链接的方法
const openLink = (link) => {
  if (link.website !== '') window.open(link.website, '_blank');
}
</script>

<style scoped lang="scss">
.homeLink {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 添加间距 */
  padding: 10px 0 10px 10px;
}

.link {
  width: calc(25% - 10px); /* 每行四个标签，减去间距 */
  display: flex;
  justify-content: center;
  align-items: center; /* 调整对齐方式 */
  background-color: #d5e3f1;
  padding: 10px;
  cursor: pointer; /* 添加鼠标指针样式 */
  border-radius: 5px; /* 添加圆角 */
  transition: background-color 0.3s; /* 添加过渡效果 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
  border: 1px solid #b8d1e9; /* 添加边框 */
}

.link:hover {
  background-color: #b8d1e9; /* 鼠标悬停时改变背景颜色 */
  border: 1px solid #d3e1ef; /* 添加边框 */
}
</style>
