<template>
  <div class="weather" @mouseover="showWeatherDetails" @mouseout="hideWeatherDetails">
    <div v-if="!isLoading && locationName !== '获取天气数据失败'">
      <span>{{ locationName }}</span>
      <template v-if="weatherIcon.success">
        <img :src="weatherIcon.value" alt="天气图标"/>
      </template>
      <span v-else>{{ weatherIcon.value }}</span>
      <span>{{ temperature }}</span>
    </div>
    <span v-else-if="isLoading">加载中...</span>
    <span v-else>获取天气数据失败</span>
  </div>

  <div class="weather_show container-style" :class="{ visible: isWeatherDetailsVisible }"
       @mouseover="showWeatherDetails" @mouseout="hideWeatherDetails">
    <div v-if="isWarnEnabled && hasSevereWeather !== '天气正常'" class="warning container-style">
      <span style="color: #d32727; font-size: 18px">{{ warnTitle  }}</span>
      <span>{{ hasSevereWeather }}</span>
    </div>
    <div class="todayDate">
      <div class="date-info">
        <span>{{ gregorianDate }}</span>
        <span class="lunar-date" style="color: #aaa;">农历 {{ lunarDate }}</span>
      </div>
      <div class="location-info" @click="showAddressSelector">
        <el-icon>
          <LocationInformation/>
        </el-icon>
        <span> 定位　</span>
      </div>
    </div>

    <div class="forecast">
      <div v-for="(forecast, index) in formattedForecasts" :key="index" class="forecast-item container-style">
        <span>{{ forecast.formattedDate }}</span>
        <template v-if="forecast.icon.success">
          <img :src="forecast.icon.value" alt="夜间天气图标"/>
        </template>
        <span v-else>{{ forecast.icon.value }}</span>
        <span>{{ forecast.weatherCondition }}</span>
        <span>{{ forecast.temperatureRange }}℃</span>
        <span>{{ forecast.windInfo }}</span>
      </div>
    </div>
  </div>

  <div class="address-selector container-style" :class="{ visible: isAddressSelectorVisible }">
    <span>请选择你的地区：</span>
    <AddressSelector
        v-if="!isLoading && selectedProvince.value !== null && selectedCity.value !== null && selectedCounty.value !== null"
        :province="selectedProvince.value"
        :city="selectedCity.value"
        :county="selectedCounty.value"
        @select-address="handleSelectAddress"
    />

    <!-- 添加单选框 -->
    <el-checkbox v-model="isWarnEnabled" label="出现大雨、大雪、冰雹等极端天气时提醒" />
    <div class="buttonsContainer">
      <button @click="handleCancel" class="cancel-button">取消</button>
      <button @click="handleConfirm" class="confirm-button">确定</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import axios from "axios";
import dayjs from 'dayjs';
import * as lunar from 'lunar-calendar';
import { LocationInformation } from "@element-plus/icons-vue";
import { ElIcon } from "element-plus";
import AddressSelector from "@/components/AddressSelector.vue";

// 定义所有 ref 和 computed 变量
const locationName = ref('');
const temperature = ref('');
const isWeatherDetailsVisible = ref(false);
const forecasts = ref([]);
const weatherIcon = ref({ success: false, value: '' });
const formattedForecasts = ref([]);
const isWarnEnabled = ref(true);
const isAddressSelectorVisible = ref(false);

const selectedProvince = ref({ value: null });
const selectedCity = ref({ value: null });
const selectedCounty = ref({ value: null });

let gregorianDate = '';
let lunarDate = '';
let warnTitle = '';
let shortCode = '';

const isLoading = ref(true);
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 计算是否有严重天气
const hasSevereWeather = computed(() => {
  const severeConditions = ['暴雨', '暴雪', '大风', '冰雹'];
  const today = dayjs().format('YYYY-MM-DD');
  const todayForecast = forecasts.value.find(forecast => forecast.date === today);

  if (todayForecast) {
    const { text_day, text_night, high, low, wc_day, wc_night } = todayForecast;
    const maxTemp = Math.max(high, low);
    const minTemp = Math.min(high, low);
    const maxWindSpeed = Math.max(wc_day, wc_night);

    let warning = '';

    if (maxTemp > 37) {
      warnTitle = '高温预警：';
      warning += `今日最高气温 ${maxTemp} 度；`;
    }
    if (minTemp < 0) {
      warnTitle = '低温预警：';
      warning += `今日最低气温 ${minTemp} 度；`;
    }
    if (severeConditions.includes(text_day)) {
      warning += `今日白天有${text_day}；`;
    }
    if (severeConditions.includes(text_night)) {
      warning += `今夜有${text_night}；`;
    }
    if (maxWindSpeed > 7) {
      warnTitle = '大风预警：';
      warning += `今日最大风速 ${maxWindSpeed} 级；`;
    }

    return warning ? warning.slice(0, -1) : '天气正常'; // 去掉最后一个分号
  }
  return '';
});

// 定义所有辅助函数
const getLocationByRegionCode = async () => {
  const districtCode = localStorage.getItem('districtCode');
  if (districtCode) return districtCode;

  try {
    const response = await axios.get(`https://api.vore.top/api/IPdata?ip=`);
    return response.data.adcode.a || '431322';
  } catch {
    return null;
  }
};

const handleSelectAddress = async (addressData) => {
  shortCode = addressData.shortCode;
};

const fetchWeather = async (adcode) => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/BaiduData/weather/${encodeURIComponent(adcode)}`);
    const data = response.data.data.result;
    locationName.value = data.location.name;
    temperature.value = data.now.temp + "℃";
    forecasts.value = data.forecasts;

    // 确保设置的是 .value 属性
    selectedProvince.value = { value: data.location.province || '' };
    selectedCity.value = { value: data.location.city || '' };
    selectedCounty.value = { value: data.location.name || '' };

    weatherIcon.value = await getWeatherIcon(data.now.text);

    const formattedForecastsArray = [];
    for (const forecast of forecasts.value) {
      const icon = await getWeatherIcon(forecast.text_night);
      const currentHour = dayjs().hour();
      const isDay = currentHour >= 6 && currentHour <= 18;
      const windDirection = isDay ? forecast.wd_day : forecast.wd_night;
      const windSpeed = isDay ? forecast.wc_day.replace('<', '') : forecast.wc_night.replace('<', '');

      formattedForecastsArray.push({
        ...forecast,
        formattedDate: formatDay(forecast.date),
        temperatureRange: `${forecast.low}~${forecast.high}`,
        weatherCondition: forecast.text_day === forecast.text_night ? forecast.text_day : `${forecast.text_day}转${forecast.text_night}`,
        windInfo: `${windDirection} ${windSpeed}`,
        icon: icon
      });
    }
    formattedForecasts.value = formattedForecastsArray;
  } catch {
    locationName.value = "获取天气数据失败";
    temperature.value = "";
  }
};

const getWeatherIcon = (weatherText) => {
  if (!weatherText) return Promise.resolve({ success: false, value: '' });

  const iconPath = `/icon/weather/${weatherText}.png`;
  const img = new Image();

  return new Promise((resolve) => {
    img.src = iconPath;
    img.onload = () => resolve({ success: true, value: iconPath });
    img.onerror = () => resolve({ success: false, value: weatherText });
  });
};

const showAddressSelector = () => {
  isAddressSelectorVisible.value = true;
  isWeatherDetailsVisible.value = false;
};

const showWeatherDetails = () => {
  isWeatherDetailsVisible.value = true;
};

const hideWeatherDetails = () => {
  isWeatherDetailsVisible.value = false;
};

const formatDay = (date) => {
  const today = dayjs().format('YYYY-MM-DD');
  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
  const dayAfterTomorrow = dayjs().add(2, 'day').format('YYYY-MM-DD');

  if (date === today) {
    return `今天(${getChineseWeekday(dayjs(date))})`;
  } else if (date === tomorrow) {
    return `明天(${getChineseWeekday(dayjs(date))})`;
  } else if (date === dayAfterTomorrow) {
    return `后天(${getChineseWeekday(dayjs(date))})`;
  } else {
    return `${dayjs(date).format('MM月DD日')}(${getChineseWeekday(dayjs(date))})`;
  }
};

const getChineseWeekday = (day) => {
  const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  return weekdays[day.day()];
};

const initDate = () => {
  const currentDate = dayjs();
  gregorianDate = currentDate.format('MM月DD日');
  convertToLunar(currentDate);
};

const convertToLunar = (date) => {
  if (date) {
    const gregorianDateObj = new Date(date.format('YYYY-MM-DD'));
    const result = lunar.solarToLunar(gregorianDateObj.getFullYear(), gregorianDateObj.getMonth() + 1, gregorianDateObj.getDate());
    if (result && !result.error) {
      const lunarMonth = result.lunarMonthName;
      const lunarDay = result.lunarDayName;
      lunarDate = `${lunarMonth}${lunarDay}`;
    }
  }
};

const handleConfirm = async () => {
  if (shortCode) {
    localStorage.setItem('districtCode', shortCode);
    await fetchWeather(shortCode);
    isAddressSelectorVisible.value = false;

    // 保存 isWarnEnabled 标记
    localStorage.setItem('isWarn', isWarnEnabled.value ? 'true' : 'false');
  }
};

const handleCancel = () => {
  isAddressSelectorVisible.value = false;
};

onMounted(async () => {
  const adcode = await getLocationByRegionCode();
  await fetchWeather(adcode);
  initDate();
  isLoading.value = false;

  // 初始化 isWarnEnabled 的值
  const isWarn = localStorage.getItem('isWarn');
  isWarnEnabled.value = isWarn !== 'false';
});
</script>

<style lang="scss" scoped>
.weather {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  span {
    margin: 0 5px;
  }

  img {
    vertical-align: middle;
    margin: 0 5px;
    width: 24px;
    height: 24px;
  }
}

.address-selector {
  position: absolute;
  height: 249px;
  z-index: 1000;
  visibility: hidden;
  transition: visibility 0.3s;
  padding: 26px;
  color: black;
  background-color: #fdfdfd;
  cursor: default;

  &.visible {
    visibility: visible;
  }
}

.weather_show {
  position: absolute;
  z-index: 1000;
  visibility: hidden;
  transition: visibility 0.3s;
  padding: 10px;
  color: black;
  background-color: #fdfdfd;
  cursor: default;

  &.visible {
    visibility: visible;
  }

  .warning {
    width: 100%;
    height: 50px;
    background-color: #f9f9f9;
    text-align: center;
    padding: 0;

    span {
      height: 32px;
      line-height: 36px;
    }
  }

  .todayDate {
    height: 59px;
    margin: 10px 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .lunar-date {
      margin-left: 15px;
    }
  }

  .location-info {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .location-info:hover {
    color: #409EFF;
  }

  .forecast {
    display: flex;
    gap: 10px;

    .forecast-item {
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      padding: 0 10px 10px;

      span {
        height: 36px;
      }

      img {
        width: 36px;
        height: 36px;
        margin-top: 18px;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;

  .cancel-button {
    width: 60px;
    height: 36px;
  }

  .confirm-button {
    width: 60px;
    height: 36px;
  }
}
</style>

