<!--主页-->
<template>
  <!-- 顶部 -->
  <Top/>

  <!-- 动态加载的头部 -->
  <Header :size="size"/>

  <!-- 动态加载的导航栏 -->
  <Navbar :size="size"/>

  <!-- 页面主体 -->
  <div class="main-container">
    <router-view></router-view>
  </div>

  <!-- 回到顶部按钮 -->
  <el-backtop :visibility-height="100"/>

  <!-- 版权信息 -->
  <Footer/>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Top from "@/views/Home/Top.vue";
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

// 获取当前路由
const route = useRoute();

// 计算属性，判断当前是否为 Home 页面
const isHome = computed(() => {
  return ['/'].includes(route.name);
});

// 计算属性，动态设置大小
const size = computed(() => isHome.value ? 'big' : 'small');
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 351px);
  padding: 20px calc((100% - 1200px) / 2);
  background: linear-gradient(to bottom, rgb(68, 156, 243), rgba(255, 255, 255, 0));
}
</style>
