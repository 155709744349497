<!--右侧边分页，展示新闻-->
<template>
  <div class="slide-tab">
    <div class="tab-labels">
      <span
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === tab.name }"
        @click="handleClick(tab.name)"
        @mouseover="showTab(tab.name)"
      >
        {{ tab.label }}
      </span>
    </div>
    <hr />
    <div v-if="newsData[activeTab] && newsData[activeTab].length > 0">
      <ul class="news-list">
        <li v-for="news in newsData[activeTab]" :key="news._id" class="news-item">
          <a href="#" class="news-title" @click.prevent="handleNewsClick(news._id, activeTab)">
            {{ news.title }}
          </a>
          <span class="news-date">{{ news.editTime.slice(0, 10) }}</span>
        </li>
      </ul>
    </div>
    <div v-else class="no-news">
      新闻读取中......
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

const router = useRouter();
const activeTab = ref("todayFocus");

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 保存每个选项卡对应的网址
const tabUrls = {
  todayFocus: "/todayFocus",
  county: "https://www.xinhua.gov.cn/xinhua/xxgk/zwyw/szyw/common_list.shtml",
  city: "https://www.hnloudi.gov.cn/loudi/0601/list.shtml",
  province: "https://www.hunan.gov.cn/hnszf/hnyw/zwdt/szdt_sjpx.html",
  government: "https://www.gov.cn/pushinfo/v150203/index.htm"
};


// 标签和对应的文章类别
const tabs = [
  { label: "今日关注", name: "todayFocus" },
  { label: "县内新闻", name: "county" },
  { label: "市内要闻", name: "city" },
  { label: "省内要闻", name: "province" },
  { label: "国务院信息", name: "government" }
];


// 保存每个选项卡对应的标题
const tabLabels = {
  todayFocus: "今日关注",
  county: "县内新闻",
  city: "市内要闻",
  province: "省内要闻",
  government: "国务院信息"
};


// 统一的数据对象
const newsData = ref({});

// 初始化 newsData 中的每个 tab 为一个空数组
tabs.forEach(tab => {
  newsData.value[tab.name] = [];
});

// 记录每个标签上次获取数据的时间
const lastFetchTimes = ref({});

// 初始化 lastFetchTimes 中的每个 tab 为 null
tabs.forEach(tab => {
  lastFetchTimes.value[tab.name] = null;
});

const handleNewsClick = (id, tabName) => {
  if (tabName === "todayFocus") {
    // 如果是“今日关注”标签，跳转到路由
    router.push({
      path: `/new/${id}`,
      query: { parentTitle: tabLabels[tabName] }
    });
  } else {
    // 否则打开外部网址
    const newsItem = newsData.value[tabName].find(item => item._id === id);
    if (newsItem && newsItem.link) {
      let fullUrl = newsItem.link;

      // 获取基础 URL
      const baseUrl = tabUrls[tabName];

      // 检查是否是非 HTTP 开头的链接并补齐网址
      if (!fullUrl.startsWith('http') && baseUrl) {
        fullUrl = new URL(newsItem.link, baseUrl).href;
      }

      window.open(fullUrl, "_blank");
    } else {
      console.error('Invalid news item or missing link:', newsItem);
    }
  }
};

const handleClick = (name) => {
  if (name === "todayFocus") {
    // 如果是“今日关注”标签，跳转到路由
    router.push(tabUrls[name]);
  } else {
    // 否则打开外部网址
    if (name) window.open(tabUrls[name], "_blank");
  }
};

// 显示当前选项卡
const showTab = async (name) => {
  activeTab.value = name;

  if (name !== 'todayFocus') {
    const currentTime = new Date().getTime();
    const lastFetchTime = lastFetchTimes.value[name];

    if (!lastFetchTime || (currentTime - lastFetchTime > 3600 * 1000)) {
      try {
        const res = await axios.get(`${baseUrl}/webapi/fetchNews/list`, {
          params: {
            limit: 9,
            url: tabUrls[name],
            type: name // 添加标记参数
          }
        });

        if (res.data && res.data.ActionType === 'OK' && res.data.data) {
          // 转换数据结构
          newsData.value[name]  = res.data.data.map((item, index) => ({
            _id: index.toString(), // 使用索引作为临时ID
            title: item.title,
            editTime: item.date,
            link: item.link // 假设每个新闻项有一个对应的link字段
          }));

          //newsData.value[name] = transformedData;
          lastFetchTimes.value[name] = currentTime;
        } else {
          console.error(`获取 ${name} 新闻列表失败: 数据格式不正确`, res.data);
        }
      } catch (error) {
        console.error(`获取 ${name} 新闻列表失败:`, error);
      }
    }
  }
};

onMounted(async () => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/slideTab/list`, {
      params: {
        limit: 9
      }
    });

    if (res.data && res.data.data) {
      newsData.value.todayFocus = res.data.data || [];
      lastFetchTimes.value.todayFocus = new Date().getTime();
    } else {
      console.error("获取今日关注新闻列表失败: 数据格式不正确", res.data);
    }
  } catch (error) {
    console.error("获取今日关注新闻列表失败:", error);
  }
});
</script>


<style lang="scss" scoped>
.slide-tab {
  background-color: rgba(243, 243, 243, 0.8);
  padding: 0 10px;
  width: 590px;
  height: 362px;

  .tab-labels {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px;

    span {
      font-size: 19px;
      padding: 5px 10px;
      cursor: pointer;
      transition: color 0.3s;

      &.active {
        color: #fdfafd;
        border-radius: 4px;
        background-color: #439bf1;
      }

      &:hover {
        color: #fdfafd;
      }
    }
  }

  hr {
    border: 1px solid #ededed;
  }

  .news-list {
    list-style: none;
    padding: 0;
    margin: 12px 5px 0 5px;

    .news-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5px 0 12px;
      font-size: 16px;

      &::before {
        content: "•"; /* 圆点符号 */
        margin-right: 8px; /* 与文本之间的间距 */
        color: #888; /* 圆点颜色 */
      }

      .news-title {
        color: #444444;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%; // 调整宽度以适应布局

        &.active {
          color: #c72c1a;
        }

        &:hover {
          color: #439bf1;
        }
      }

      .news-date {
        color: #888;
        font-size: 14px;
        margin-left: auto; /* 确保时间靠右对齐 */
      }
    }
  }

  // 无新闻时的提示样式
  .no-news {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 150px;
  }
}
</style>

