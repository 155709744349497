<!--免责申明-->
<template>
  <div v-if="isVisible" class="popup-container container-style" @mouseenter="clearTimer" @mouseleave="resetTimer">
    <div class="popup-content">
      <button class="close-button" @click="closePopup">
        <el-icon><CloseBold /></el-icon>
      </button>
      <h3>网站建设、调试中，敬请期待......</h3>
      <p>测试阶段，部分素材来源于网络，侵权立删。</p>
      <p>您有疑问或建议，请联系站长(e代农夫)。</p>
      <p>您有好的素材或好的创意，可联系站长，让我们一起把它打造得更好。</p>
      <p>您的任何梦想，在这里都可以变成现实，请直接在网站首页下面留言。</p>
      <p>您可以申请账号，在网站上发表新闻、发布通告、公告、上传图片。</p>
      <p>请遵守互联网相关规定。</p>
      <p>感谢您的关注。</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';

const props = defineProps({
  removeTimer: {
    type: Boolean,
    default: false
  }
});

const isVisible = ref(true);
let timer;

const closePopup = () => {
  isVisible.value = false;
  clearTimeout(timer);
};

const clearTimer = () => {
  clearTimeout(timer);
};

const resetTimer = () => {
  if (!props.removeTimer) {
    timer = setTimeout(() => {
      closePopup();
    }, 10000);
  }
};

onUnmounted(() => {
  clearTimeout(timer);
});

onMounted(() => {
  resetTimer();
});
</script>

<style scoped lang="scss">
.popup-container {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  height: 408px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

h3 {
  margin-bottom: 30px; /* 设置 h3 标签下方间距为 30px */
}

p {
  margin-bottom: 20px; /* 设置 p 标签之间的间距为 20px */
}

p:last-child {
  margin-bottom: 0; /* 最后一个 p 标签不设置底部间距 */
}
</style>
