<!--行政规划-->
<template>
  <div class="overview-container">
    <p class="preserve-newlines" v-html="formattedOverview"></p>
    <table class="custom-table">
      <colgroup>
        <col style="width: 160px;">
        <col span="5" style="width: 18%;">
      </colgroup>
      <tbody>
        <tr>
          <td rowspan="2">圳上群工办</td>
          <td>大桥社区</td>
          <td>鼎英社区</td>
          <td>久大社区</td>
          <td>圳上村</td>
          <td>方荣华村</td>
        </tr>
        <tr>
          <td>干家村</td>
          <td>平山村</td>
          <td>永胜村</td>
          <td>铁石冲村</td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2">山溪群工办</td>
          <td>山溪村</td>
          <td>南山村</td>
          <td>大军岭村</td>
          <td>太原村</td>
          <td>新泉村</td>
        </tr>
        <tr>
          <td>龙山村</td>
          <td>洪家村</td>
          <td>新兴村</td>
          <td>子余村</td>
          <td></td>
        </tr>
        <tr>
          <td>株梓群工办</td>
          <td>株梓村</td>
          <td>大竹村</td>
          <td>早溪村</td>
          <td>鲁溪村</td>
          <td>董溪村</td>
        </tr>
        <tr>
          <td rowspan="2">海龙群工办</td>
          <td>海和村</td>
          <td>永龙村</td>
          <td>六丰村</td>
          <td>小熊山村</td>
          <td>石中村</td>
        </tr>
        <tr>
          <td>杉山村</td>
          <td>封家村</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2">松山群工办</td>
          <td>松山村</td>
          <td>金竹村</td>
          <td>徐家村</td>
          <td>东风村</td>
          <td>罗盛教村</td>
        </tr>
        <tr>
          <td>黄溪村</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="3">半山群工办</td>
          <td>半山村</td>
          <td>金炉村</td>
          <td>董易村</td>
          <td>曙光村</td>
          <td>中路村</td>
        </tr>
        <tr>
          <td>黎明村</td>
          <td>江下村</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const data = {
  overview: "截至2024年10月，全镇辖3个社区、40个行政村，外派六个群工办，协助行政村工作。镇人民政府驻鼎英村。\n" +
            "社区：大桥社区、鼎英社区、久大社区，隶属圳上群工办。"
};

// 处理字符串，在每一行前增加两个中文空格
const formattedOverview = data.overview.split('\n').map(line => '　　' + line).join('<br>');
</script>

<style lang="scss" scoped>
.overview-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;

  .preserve-newlines {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 2;
    color: #333;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;

    th,
    td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
    }
  }
}
</style>
