<!--搜索新闻-->
<template>
  <div class="search">
    <el-popover
        :visible="visible"
        placement="bottom"
        title="检索结果"
        width="50%"
    >
      <template #reference>
        <el-input
            v-model="searchText"
            :prefix-icon="Search"
            class="w-50 m-2"
            placeholder="请输入新闻关键字"
            size="large"
            type="search"
            @blur="visible = false"
            @input="handleSearch"
            @focus="fetchNewsTitles"
        />
      </template>

      <div v-if="searchnewslist.length">
        <div
            v-for="data in searchnewslist"
            :key="data._id"
            class="search-item pointer"
            @click="handleChangePage(data._id)"
        >
          {{ data.title }}
        </div>
      </div>
      <div v-else>
        <el-empty
            :image-size="50"
            description="暂无新闻"
        />
      </div>
    </el-popover>
  </div>
</template>

<script setup>
import {Search} from '@element-plus/icons-vue';
import {ref, computed} from 'vue';
import {debounce} from 'lodash';
import {useRouter} from 'vue-router';
import axios from 'axios';

const searchText = ref('');
const visible = ref(false);
const newsTitles = ref([]);

const debouncedSearch = debounce(() => {
  visible.value = !!searchText.value;
}, 300);

const handleSearch = (value) => {
  searchText.value = value;
  debouncedSearch();
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const fetchNewsTitles = async () => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/news/titles`);
    newsTitles.value = res.data.data;
  } catch (error) {
    console.error('获取新闻标题列表失败:', error);
  }
};

const searchnewslist = computed(() =>
    searchText.value
        ? newsTitles.value.filter(item => item.title.includes(searchText.value))
        : []
);

const router = useRouter();

const handleChangePage = (id) => {
  router.push({
    path: `/new/${id}`,
    query: {parentTitle: '新闻中心'}
  });
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 94px;
  width: 100%;
  text-align: center;

  .el-input {
    width: 50%;
  }
}

.search-item {
  height: 50px;
  line-height: 50px;
}
</style>
