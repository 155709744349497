<!--检索结果-->
<template>
  <el-container>
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item @click="router.go(-1)">
          <div class="breadcrumb-item-content">
            <el-icon class="back">
              <Back />
            </el-icon>
            <span class="text">首页</span>
          </div>
        </el-breadcrumb-item>
        <el-breadcrumb-item>检索结果</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>

    <el-container>
      <el-aside>
        <el-row :gutter="20">
          <el-col :span="24">
            <h3>部门</h3>
            <ul v-if="departments.length">
              <li v-for="department in departments" :key="department._id" @click="goToDepartments(department.name)">
                {{ department.name }}
              </li>
            </ul>
            <p v-else class="no-results">（未搜索到相关部门）</p>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="padding-top: 10px">
          <el-col :span="24">
            <h3>人员</h3>
            <ul v-if="persons.length">
              <li v-for="person in persons" :key="person._id" @click="goToPerson(person._id)">
                {{ person.name }}
              </li>
            </ul>
            <p v-else class="no-results">（未搜索到相关人员）</p>
          </el-col>
        </el-row>
      </el-aside>

      <el-main>
        <el-row :gutter="20">
          <el-col :span="24">
            <h3>新闻</h3>
            <ul v-if="news.length" class="news-list">
              <li v-for="newsItem in displayedNews" :key="newsItem._id" @click="goToNews(newsItem._id)">
                {{ newsItem.title }}
              </li>
            </ul>
            <p v-else class="no-results">（未搜索到相关新闻）</p>
          </el-col>
        </el-row>

        <el-row class="pagination" v-if="news.length">
          <el-col :span="24">
            <Pagination
                :total="news.length"
                :page-size="pageSize"
                :current-page="currentPage"
                @update:currentPage="handlePageChange"
            />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>

  <DepartmentStaffInfo
      :modelValue="showStaffInfoDialog"
      :name="selectedStaffInfoName"
      @close="closeStaffInfoDialog"
  />

  <StaffDetails
      v-if="showStaffDetailsDialog"
      :id="selectedStaffDetailsId"
      @close="closeStaffDetailsDialog"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { Back } from "@element-plus/icons-vue";
import DepartmentStaffInfo from "@/views/Publicize/DepartmentStaffInfo.vue";
import StaffDetails from "@/views/Publicize/StaffDetails.vue";
import Pagination from "@/components/Pagination.vue";
import { useStore } from "vuex";
import { ElBreadcrumb, ElBreadcrumbItem, ElIcon } from "element-plus";

const store = useStore();
const searchResults = computed(() => store.state.searchResults);

const router = useRouter();

const departments = computed(() => searchResults.value.departments);
const news = computed(() => searchResults.value.news);
const persons = computed(() => searchResults.value.persons);

const selectedStaffInfoName = ref(null);
const showStaffDetailsDialog = ref(false);

const selectedStaffDetailsId = ref(null);
const showStaffInfoDialog = ref(false);

const currentPage = ref(1);
const pageSize = ref(10);

const displayedNews = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return news.value.slice(start, end);
});

const goToNews = (id) => {
  router.push({
    path: `/new/${id}`,
    query: { parentTitle: '搜索结果' }
  });
};

const goToDepartments = (name) => {
  selectedStaffInfoName.value = name;
  showStaffInfoDialog.value = true;
};

const goToPerson = (id) => {
  selectedStaffDetailsId.value = id;
  showStaffDetailsDialog.value = true;
};

const closeStaffInfoDialog = () => {
  showStaffInfoDialog.value = false;
};

const closeStaffDetailsDialog = () => {
  showStaffDetailsDialog.value = false;
};

const handlePageChange = (newPage) => {
  currentPage.value = newPage;
};
</script>

<style lang="scss" scoped>
.el-header {
  width: 100%;
  height: 50px;
  background-color: #cee2f5;

  .breadcrumb-item-content {
    display: flex;
    align-items: center;

    .back {
      margin-right: 10px;
    }

    .text {
      font-size: 15px;
      color: #414141;
    }
  }

  .el-breadcrumb {
    font-size: 16px;
    padding-top: 20px;
  }
}

.el-aside {
  width: 220px;
  padding: 20px;
  background: linear-gradient(to bottom, #fdfdfd, rgba(255, 255, 255, 0));
}

.el-main {
  width: 100%;
  height: 549px;
  padding: 20px 20px 0;
  background: linear-gradient(to bottom, #e4e4e4, rgba(255, 255, 255, 0));
}

h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;

  li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    color: #434343;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.no-results {
  color: #c4c4c4;
}

.news-list {
  min-height: 491px;
}
</style>
