// 路由配置
import {createRouter, createWebHistory} from 'vue-router';
import Home from "@/views/Home/Home.vue";
import TodayFocus from "@/views/Home/TodayFocus.vue";
import Media from "@/views/News/News.vue";
import NewsHot from "@/views/News/NewsHot.vue";
import New from "@/views/News/New.vue";
import Gozsz from "@/views/Gozsz/Gozsz.vue";
import Administration from "@/views/Gozsz/Administration.vue";
import Culture from "@/views/Gozsz/Culture.vue";
import Geography from "@/views/Gozsz/Geography.vue";
import History from "@/views/Gozsz/History.vue";
import Overview from "@/views/Gozsz/Overview.vue";
import Public from "@/views/Publicize/Publicize.vue";
import Pioneer from "@/views/Pioneer/Pioneer.vue";
import Service from "@/views/Service/Service.vue";
import NotFound from "@/views/notfound/NotFound.vue";
import AboutUs from "@/components/AboutUs.vue";
import SearchResults from "@/views/Home/ SearchResults.vue";


const routes = [
    {path: "/", name: "/", component: Home},
    {path: "/searchResults/:", name: "searchResults", component: SearchResults},
    {path: "/todayFocus", name: "todayFocus", component: TodayFocus},
    {path: "/public", name: "public", component: Public,},
    {path: "/pioneer", name: "pioneer", component: Pioneer},
    {path: "/service", name: "service", component: Service},
    
    {path: "/media", name: "media", component: Media},
    {path: "/newsHot", name: "newsHot", component: NewsHot},
    {path: "/new/:id", name: "new", component: New},
    {
        path: "/gozsz",
        name: "gozsz",
        component: Gozsz,
        children: [
            {path: "administration", name: "administration", component: Administration},
            {path: "culture", name: "culture", component: Culture},
            {path: "geography", name: "geography", component: Geography},
            {path: "history", name: "history", component: History},
            {path: "overview", name: "overview", component: Overview},
            {path: "", name: "default", redirect: {name: "overview"}} // 为默认重定向添加名称
        ]
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {path: "/:pathMatch(.*)*", name: "not-found", component: NotFound}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 添加导航守卫
router.beforeEach((to, from, next) => {
    // 设置固定的标题
    document.title = '圳上镇信息服务平台(测试)';
    next();
});

export default router;
