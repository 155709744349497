// 搜索组件
<template>
  <form class="search-form" @submit.prevent="handleSearch">
    <input v-model="searchQuery" class="search-input" placeholder="请输入关键词查询" required type="text">
    <button class="search-button" type="submit">搜索</button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
const searchQuery = ref("");
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 搜索功能
const handleSearch = async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/search/${encodeURIComponent(searchQuery.value)}`);
    const results = {
      departments: response.data.data.departments || [],
      news: response.data.data.news || [],
      persons: response.data.data.persons || []
    };
    await store.dispatch('setSearchResults', results);
    await router.push({
      path: '/searchResults',
      query: { parentTitle: '网站首页' }
    });
  } catch (error) {
    console.error("搜索失败:", error);
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  .search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none; /* 去掉输入框聚焦时的默认边框 */
  }

  .search-button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 4px 4px 0;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3; /* 鼠标悬停时改变背景颜色 */
    }
  }
}
</style>